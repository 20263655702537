import { generatePath } from 'react-router'

import { get, isArray, isEqual, mapValues } from 'lodash-es'

import { CreateUserFavouriteParams, UserFavourite } from './hooks'

import { PrimaryApplication, SecondaryApplication } from '../UserEvents'

import { parseUrlSearchParams, stringifyUrlSearchParams } from '@percept/hooks'

import { Dictionary } from '@percept/types'


const { APP } = process.env

export type LocationLike = {
  pathname: string
  search: string
}

type ClassificationRule = {
  appFilter?: (typeof APP)[]
  pathPattern: RegExp
  route?: string
  primaryApplication: PrimaryApplication
  secondaryApplication?: SecondaryApplication
  getViewParameters?: (location: LocationLike) => Dictionary | null
}

const isValidAppClassificationRule = (classificationRule: ClassificationRule): boolean => (
  !classificationRule.appFilter || classificationRule.appFilter.includes(APP)
)

const makeGetRegexMatch = (regex: RegExp) => (value: string): string | null => {
  const match = regex.exec(value)
  if( match ){
    return match[1]
  }
  return null
}

const getSecondRouteFragment = makeGetRegexMatch(/\/[^/]+\/([^/]+)/)
const getThirdRouteFragment = makeGetRegexMatch(/\/[^/]+\/[^/]+\/([^/]+)/)
const getFourthRouteFragment = makeGetRegexMatch(/\/[^/]+\/[^/]+\/[^/]+\/([^/]+)/)

const appClassificationRules: ClassificationRule[] = [
  // Media Wizard Routes
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/?$/,
    route: '/wizard/home',
    primaryApplication: 'VODAFONE_MEDIA_WIZARD',
    secondaryApplication: 'WIZARD_HOME',
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/wizard(\/home)?$/,
    route: '/wizard/home',
    primaryApplication: 'VODAFONE_MEDIA_WIZARD',
    secondaryApplication: 'WIZARD_HOME',
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/wizard\/optimisation$/,
    route: '/wizard/optimisation',
    primaryApplication: 'VODAFONE_MEDIA_WIZARD',
    secondaryApplication: 'OPTIMISATION',
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/wizard\/optimisation\/partnerships.*/,
    route: '/wizard/optimisation/partnerships',
    primaryApplication: 'VODAFONE_MEDIA_WIZARD',
    secondaryApplication: 'PARTNERSHIPS',
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/wizard\/optimisation\/sponsorships.*/,
    route: '/wizard/optimisation/sponsorships',
    primaryApplication: 'VODAFONE_MEDIA_WIZARD',
    secondaryApplication: 'SPONSORSHIPS',
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/wizard\/reporting$/,
    route: '/wizard/reporting',
    primaryApplication: 'VODAFONE_MEDIA_WIZARD',
    secondaryApplication: 'REPORTING',
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/wizard\/reporting\/smart-campaign-assessment.*/,
    route: '/wizard/reporting/smart-campaign-assessment',
    primaryApplication: 'VODAFONE_MEDIA_WIZARD',
    secondaryApplication: 'SMART_CAMPAIGN_ASSESSMENT',
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/wizard\/reporting\/wastage-trend-reports.*/,
    route: '/wizard/reporting/wastage-trend-reports',
    primaryApplication: 'VODAFONE_MEDIA_WIZARD',
    secondaryApplication: 'WASTAGE_TREND_REPORTS',
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/wizard\/strategy$/,
    route: '/wizard/strategy',
    primaryApplication: 'VODAFONE_MEDIA_WIZARD',
    secondaryApplication: 'STRATEGY',
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/wizard\/strategy\/brand-growth-planner.*/,
    route: '/wizard/strategy/brand-growth-planner',
    primaryApplication: 'VODAFONE_MEDIA_WIZARD',
    secondaryApplication: 'BRAND_GROWTH_PLANNER',
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/wizard\/strategy\/pathways.*/,
    route: '/wizard/strategy/pathways',
    primaryApplication: 'VODAFONE_MEDIA_WIZARD',
    secondaryApplication: 'PATHWAYS',
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/wizard\/strategy\/media-mix-modelling.*/,
    route: '/wizard/strategy/media-mix-modelling',
    primaryApplication: 'VODAFONE_MEDIA_WIZARD',
    secondaryApplication: 'MEDIA_MIX_MODELLING',
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/wizard\/overview.*$/,
    route: '/wizard/overview',
    primaryApplication: 'VODAFONE_MEDIA_WIZARD',
    secondaryApplication: 'WIZARD_OVERVIEW',
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/wizard\/news$/,
    route: '/wizard/news',
    primaryApplication: 'VODAFONE_MEDIA_WIZARD',
    secondaryApplication: 'NEWS',
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/wizard\/analytics$/,
    route: '/wizard/analytics',
    primaryApplication: 'VODAFONE_MEDIA_WIZARD',
    secondaryApplication: 'ANALYTICS',
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/wizard\/user-management$/,
    route: '/wizard/user-management',
    primaryApplication: 'VODAFONE_MEDIA_WIZARD',
    secondaryApplication: 'USER_MANAGEMENT',
  },
  // Media Investment routes
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/reporting\/?([0-9]+)?\/?$/,
    primaryApplication: 'MEDIA_INVESTMENT',
    route: '/reporting/:org_unit_id',
    getViewParameters: (location): Dictionary => ({
      org_unit_id: getSecondRouteFragment(location.pathname),
    })
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/reporting\/([0-9]+\/)?media-investment\/.*$/,
    route: '/reporting/:org_unit_id/media-investment/:view',
    primaryApplication: 'MEDIA_INVESTMENT',
    secondaryApplication: 'PRIMARY_INVESTMENT',
    getViewParameters: (location): Dictionary => ({
      org_unit_id: getSecondRouteFragment(location.pathname),
      view: getFourthRouteFragment(location.pathname),
    })
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/reporting\/([0-9]+\/)?competitive-investment-sos(\/.*)?$/,
    route: '/reporting/:org_unit_id/competitive-investment-sos/:view',
    primaryApplication: 'MEDIA_INVESTMENT',
    secondaryApplication: 'COMPETITIVE_INVESTMENT_SOS',
    getViewParameters: (location): Dictionary => ({
      org_unit_id: getSecondRouteFragment(location.pathname),
      view: getFourthRouteFragment(location.pathname),
    })
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/reporting\/([0-9]+\/)?competitive-investment-sov(\/.*)?$/,
    route: '/reporting/:org_unit_id/competitive-investment-sov/:view',
    primaryApplication: 'MEDIA_INVESTMENT',
    secondaryApplication: 'COMPETITIVE_INVESTMENT_SOV',
    getViewParameters: (location): Dictionary => ({
      org_unit_id: getSecondRouteFragment(location.pathname),
      view: getFourthRouteFragment(location.pathname),
    })
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/reporting\/([0-9]+\/)?competitive-investment\/?$/,
    route: '/reporting/:org_unit_id/competitive-investment',
    primaryApplication: 'MEDIA_INVESTMENT',
    secondaryApplication: 'COMPETITIVE_INVESTMENT_SOS',
    getViewParameters: (location): Dictionary => ({
      org_unit_id: getSecondRouteFragment(location.pathname),
    })
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/reporting\/([0-9]+\/)?(competitive-investment\/)?share-of-spend\/?$/,
    route: '/reporting/:org_unit_id/competitive-investment/share-of-spend',
    primaryApplication: 'MEDIA_INVESTMENT',
    secondaryApplication: 'COMPETITIVE_INVESTMENT_SOS',
    getViewParameters: (location): Dictionary => ({
      org_unit_id: getSecondRouteFragment(location.pathname),
    })
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/reporting\/([0-9]+\/)?competitive-investment\/tv-share-of-voice.*$/,
    route: '/reporting/:org_unit_id/competitive-investment/tv-share-of-voice',
    primaryApplication: 'MEDIA_INVESTMENT',
    secondaryApplication: 'COMPETITIVE_INVESTMENT_SOV',
    getViewParameters: (location): Dictionary => ({
      org_unit_id: getSecondRouteFragment(location.pathname),
    })
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/reporting\/([0-9]+\/)?submission-tracker.*$/,
    route: '/reporting/:org_unit_id/submission-tracker/:view',
    primaryApplication: 'MEDIA_INVESTMENT',
    secondaryApplication: 'SUBMISSION_TRACKER',
    getViewParameters: (location): Dictionary => ({
      org_unit_id: getSecondRouteFragment(location.pathname),
      view: getFourthRouteFragment(location.pathname),
    })
  },
  {
    appFilter: ['vodafone'],
    pathPattern: /^\/reporting\/([0-9]+\/)?investment-form.*/,
    route: '/reporting/:org_unit_id/investment-form',
    primaryApplication: 'MEDIA_INVESTMENT',
    secondaryApplication: 'WEB_FORM',
    getViewParameters: (location): Dictionary => ({
      org_unit_id: getSecondRouteFragment(location.pathname),
    })
  },
  // Dashboard routes
  // NOTE - almost all of these apply to the dashboard app when
  // hosted by the Vodafone media wizard, so we only need the app filter
  // for specific routes which may clash with media wizard routes
  {
    appFilter: ['dashboard'],
    pathPattern: /^\/?(sign-in)?$/,
    route: '/',
    primaryApplication: 'MEDIA_QUALITY_DASHBOARD',
  },
  {
    pathPattern: /^\/dashboards(\/[0-9]+)?\/?$/,
    route: '/dashboards/:org_unit_id',
    primaryApplication: 'MEDIA_QUALITY_DASHBOARD',
    secondaryApplication: 'OVERVIEW',
    getViewParameters: (location): Dictionary => ({
      org_unit_id: getSecondRouteFragment(location.pathname),
    })
  },
  {
    pathPattern: /^\/dashboards\/[0-9]+\/(performance|facebook|google-ads|tiktok|amazon-ads|adform|dv360)$/,
    route: '/dashboards/:org_unit_id/:view',
    primaryApplication: 'MEDIA_QUALITY_DASHBOARD',
    secondaryApplication: 'PERFORMANCE_OVERVIEW',
    getViewParameters: (location): Dictionary => ({
      org_unit_id: getSecondRouteFragment(location.pathname),
      view: getThirdRouteFragment(location.pathname),
    })
  },
  {
    pathPattern: /^\/dashboards\/[0-9]+\/performance-report.*/,
    route: '/dashboards/:org_unit_id/performance-report',
    primaryApplication: 'MEDIA_QUALITY_DASHBOARD',
    secondaryApplication: 'CUSTOM_PERFORMANCE_REPORT',
    getViewParameters: (location): Dictionary => ({
      org_unit_id: getSecondRouteFragment(location.pathname),
    })
  },
  {
    pathPattern: /^\/dashboards\/[0-9]+\/media-quality-rate.*/,
    route: '/dashboards/:org_unit_id/media-quality-rate',
    primaryApplication: 'MEDIA_QUALITY_DASHBOARD',
    secondaryApplication: 'MEDIA_QUALITY_RATE',
    getViewParameters: (location): Dictionary => ({
      org_unit_id: getSecondRouteFragment(location.pathname),
    })
  },
  {
    pathPattern: /^\/dashboards\/[0-9]+\/data-warehouse.*/,
    route: '/dashboards/:org_unit_id/data-warehouse',
    primaryApplication: 'MEDIA_QUALITY_DASHBOARD',
    secondaryApplication: 'DATA_WAREHOUSE',
    getViewParameters: (location): Dictionary => ({
      org_unit_id: getSecondRouteFragment(location.pathname),
    })
  },
  {
    pathPattern: /^\/series\/[0-9]+(\/insights-reports\/.*)?$/,
    route: '/series/:series_id',
    primaryApplication: 'MEDIA_QUALITY_DASHBOARD',
    secondaryApplication: 'INSIGHTS',
    getViewParameters: (location): Dictionary => ({
      series_id: getSecondRouteFragment(location.pathname),
    })
  },
  {
    pathPattern: /^\/series\/[0-9]+\/reports\/[0-9]+.*/,
    route: '/series/:series_id/reports/:report_id',
    primaryApplication: 'MEDIA_QUALITY_DASHBOARD',
    secondaryApplication: 'REPORT_DASHBOARD',
    getViewParameters: (location): Dictionary => ({
      series_id: getSecondRouteFragment(location.pathname),
      report_id: getFourthRouteFragment(location.pathname),
    })
  },
]


export const locationToUserFavourite = (location: LocationLike): Omit<CreateUserFavouriteParams, 'name'> | null => {
  for( const classification of appClassificationRules ){
    if( !isValidAppClassificationRule(classification) ){
      continue
    }
    if( classification.pathPattern.test(location.pathname) ){
      const { primaryApplication, secondaryApplication, getViewParameters } = classification
      return {
        primary_application: primaryApplication,
        secondary_application: secondaryApplication || null,
        view_parameters: {
          ...getViewParameters && getViewParameters(location) || {},
          query_params: parseUrlSearchParams(location.search),
        }
      }
    }
  }
  return null
}


export const userFavouriteToLocation = (userFavourite: UserFavourite): LocationLike | null => {
  for( const classification of appClassificationRules ){
    if( !isValidAppClassificationRule(classification) ){
      continue
    }
    if(
      classification.route
      && classification.primaryApplication === userFavourite.primary_application
      && (
        userFavourite.secondary_application ?
          classification.secondaryApplication === userFavourite.secondary_application :
          true
      )
    ){
      let pathname = ''
      try{
        pathname = generatePath(
          classification.route, userFavourite.view_parameters || {}
        )
      }catch(e){
        return null
      }
      let search = ''
      const query_params = get(userFavourite.view_parameters, 'query_params')
      if( query_params ){
        search = stringifyUrlSearchParams(query_params)
      }
      return { pathname, search }
    }
  }
  return null
}


export const locationToUrl = (location: LocationLike): string => {
  let suffix = ''
  if( location.search ){
    suffix = location.search.startsWith('?') ? location.search : `?${location.search}`
  }
  return location.pathname + suffix
}


const getStableQueryParams = (searchString: string): Dictionary => {
  return mapValues(
    parseUrlSearchParams<Dictionary>(searchString),
    (v) => isArray(v) ? v.sort() : v
  )
}


export const areLocationsEqual = (a: LocationLike, b: LocationLike): boolean => {
  return a.pathname === b.pathname && isEqual(
    getStableQueryParams(a.search), getStableQueryParams(b.search),
  )
}
