import { CurrencyType } from '../types'

export enum ScopeOfDataType {
  Market = 'market',
  Period = 'period',
  CampaignPillar = 'campaign-pillar',
  CampaignSubPillar = 'campaign-sub-pillar',
  MediaMix = 'media-mix',
  DigitalMediaMix = 'digital-media-mix',
  BrandVsPerformance = 'brand-vs-performance',
}

export enum CampaignPillarSectionTitle {
  CampaignPillar = 'Campaign Pillar',
  CampaignSubPillar = 'Campaign Sub Pillar',
  BrandPerformance = 'Brand vs Performance',
  ConsumerBusiness = 'Consumer vs Business',
}

export enum MediaMixSectionTitle {
  MediaInvest = 'Media Invest by Channel',
  MediaMixPercentage = 'Media Mix YOY in %',
  TVDigital = 'Media Mix TV vs Digital',
}

export enum MediaInvestmentPath {
  CampaignPillar = 'campaign-pillar',
  MediaMix = 'media-invest-by-channel',
  SecondBrand = 'second-brand',
  BrandMessaging = 'brand-messaging',
  Product = 'product',
  FundingSource = 'funding-source',
}

export type CompetitiveReportPath = 
 | 'share-of-spend'
 | 'tv-share-of-voice'
 | 'competitors'
 | 'vodafone'
 | 'comparison'

export type CompetitiveReportKey = 
  // SOS
  | 'sos-overview'
  | 'sos-by-market'
  | 'sos-largest-competitor'
  | 'sos-gap-to-largest-competitor'
  | 'sos-trend-vs-largest-competitor'
  | 'sos-gap-to-nearest-competitor'
  // SOV
  | 'sov-overview'
  | 'sov-by-market'
  | 'sov-largest-competitor'
  | 'sov-gap-to-largest-competitor'
  | 'sov-trend-vs-largest-competitor'
  | 'sov-gap-to-nearest-competitor'

export type ExportPeriod = 'monthly' | 'quarterly' | 'yearly'

export type MediaSpendExportType =
  | 'brand_vs_performance'
  | 'campaign_pillar'
  | 'campaign_sub_pillar'
  | 'consumer_vs_business'
  | 'funding_source'
  | 'media_mix'
  | 'message'
  | 'product'
  | 'second_brand'

export type MediaSpendExportSubType = 
  | 'brand_vs_performance'
  | 'campaign_pillar'
  | 'campaign_sub_pillar'
  | 'digital_media_mix'
  | 'market'
  | 'media_mix'
  | 'period'
  | 'tv_vs_digital'
  | 'year_over_year'

export type MediaSpendExportFilterParams = {
  markets?: string[]
  financial_months?: string[]
  financial_quarters?: string[]
  financial_years?: string[]
  years?: string[]
  media_channels?: string[]
  media_sub_channels?: string[]
  campaigns?: string[]
  sub_campaigns?: string[]
  second_brands?: string[]
  funding_sources?: string[]
  buy_types?: string[]
  jbps?: string[]
}

export type MediaSpendExportRequestParams = {
  export_type: MediaSpendExportType
  group: ExportPeriod
  export_sub_type?: MediaSpendExportSubType
  start_date?: string
  end_date?: string
  currency_type?: CurrencyType
} & MediaSpendExportFilterParams
