import axios from 'axios'

import { anonymisingResponseTransform } from '@percept/lorem'


const { API_ROOT } = process.env

const api = axios.create({
  baseURL: API_ROOT
})

if( process.env.APP === 'demo-dashboard' ){
  api.interceptors.response.use(
    anonymisingResponseTransform, err => Promise.reject(err)
  )
}

export default api
