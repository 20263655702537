import { FilterItem, OptionType } from './typings'
import Api, {
  BaseCompetitiveFilterType,
  BaseCompetitiveFiltersResponse,
  CompetitiveFilter,
  CompetitiveFiltersResponse,
  CompetitiveSOSFiltersResponse,
  CompetitiveSOVFiltersResponse,
  SOSFilter,
  SOVFilter,
} from '../../../api/services/Api'
import { Filters as ListOfFilters } from '../../../enums/Filters'
import { useMemo } from 'react'
import { investmentFilterToOptionType } from '../utils'
import { UseQueryResult } from 'react-query'
import { sortBy } from 'lodash-es'


const filterMap: Record<string, SOSFilter | SOVFilter | CompetitiveFilter> = {
  agency: 'agency',
  [ListOfFilters.Competitor]: 'competitor',
  [ListOfFilters.Markets]: 'org_unit',
  [ListOfFilters.MediaChannel]: 'media_channel',
  [ListOfFilters.ActualCompetitor]: 'actual_competitor',
  [ListOfFilters.CompetitiveSecondBrand]: 'competitive_second_brand',
  [ListOfFilters.Source]: 'source',
  [ListOfFilters.SpotLength]: 'spot_length',
  [ListOfFilters.TimeLag]: 'time_lag',
  [ListOfFilters.TradedAudience]: 'traded_audience',
  [ListOfFilters.AgencyDiscount]: 'agency_discount',
  [ListOfFilters.MediaSubChannel]: 'media_sub_channel',
}

const flattenBaseCompetitiveFilters = (response: BaseCompetitiveFiltersResponse): Record<BaseCompetitiveFilterType, OptionType[]> => {
  return {
    actual_competitor: sortBy(response.competitors.map(investmentFilterToOptionType), 'name'),
    agency: response.agencies.map(investmentFilterToOptionType),
    competitor: sortBy(response.competitors.map(investmentFilterToOptionType), 'name'),
    source: response.sources.map(investmentFilterToOptionType),
    time_lag: response.time_lags.map(investmentFilterToOptionType),
    competitive_second_brand: sortBy(response.competitive_second_brands.map(investmentFilterToOptionType), 'name'),
  }
}


const flattenSOSFiltersResponse = (response: CompetitiveSOSFiltersResponse): Record<SOSFilter, OptionType[]> => {
  return {
    ...flattenBaseCompetitiveFilters(response),
    agency_discount: response.agency_discounts.map(investmentFilterToOptionType),
    media_channel: response.media_channels.map(investmentFilterToOptionType),
    org_unit: response.org_units.map(investmentFilterToOptionType),
  }
}

const flattenSOVFiltersResponse = (response: CompetitiveSOVFiltersResponse): Record<SOVFilter, OptionType[]> => {
  return {
    ...flattenBaseCompetitiveFilters(response),
    spot_length: response.spot_lengths.map(investmentFilterToOptionType),
    traded_audience: response.traded_audiences.map(investmentFilterToOptionType),
  }
}

const flattenCompetitiveFiltersResponse = (response: CompetitiveFiltersResponse): Record<CompetitiveFilter, OptionType[]> => {
  return {
    actual_competitor: response.competitors.map(investmentFilterToOptionType),
    competitor: response.competitors.map(investmentFilterToOptionType),
    media_channel: response.media_channels.map(investmentFilterToOptionType),
    media_sub_channel: response.media_channels.map(investmentFilterToOptionType),
    org_unit: response.markets.map(investmentFilterToOptionType),
  }
}


const makeCompetitiveFilterHook = <T extends object>(
  useFilters: () => UseQueryResult<T, Error>,
  responseAdapter: (response: T) => Record<string, OptionType[]>
) => {
  return (filterItems: FilterItem[]) => {
    const response = useFilters()

    const filters = useMemo<FilterItem[]>(() => {
      if( !response.data ){
        return []
      }
      const flattenedFilters = responseAdapter(response.data)
      return filterItems.map( filterItem => {
        return {
          ...filterItem,
          groups: filterItem.groups.map((group) => {
            const { group_value } = group
            const key = filterMap[group_value]
            if( key && flattenedFilters[key] ){
              return {...group, options: flattenedFilters[key]}
            }
            const staticFilters = ['start-month', 'end-month']
            if( !staticFilters.includes(group_value) ){
              console.warn('MISSING FILTER MAPPING', group_value, key)
            }
            return group
          }),
        }
      })
    }, [response.data, filterItems])
  
    return { result: filters, loading: response.isLoading }
  }
}

export const useCompetitiveSOSFilters = makeCompetitiveFilterHook(
  Api.useCompetitiveSOSFilters,
  flattenSOSFiltersResponse
)

export const useCompetitiveSOVFilters = makeCompetitiveFilterHook(
  Api.useCompetitiveSOVFilters,
  flattenSOVFiltersResponse
)

// NOTE - for now we're using the SOS filters endpoint, because we need
// to persist the common subset of filters between SOS and SOV
export const useCompetitiveFilters = makeCompetitiveFilterHook(
  Api.useCompetitiveSOSFilters,
  flattenSOSFiltersResponse,
)
