import React, { Component, Fragment } from 'react'

import { ConnectedRouter } from 'connected-react-router'

import {
  AppThemeProvider,
  Box,
  CssBaseline,
  ScrollToTop,
  GlobalCss,
  MuiPickersUtilsProvider,
  vodafoneAppTheme,
  AppBar,
  Typography,
  BackdropLoader,
  PlainTextButton,
} from '@percept/mui'

import {
  Authenticator,
  AuthenticatorProps,
  ErrorBoundary,
  NotificationHub,
  ToastHub,
  UserPrivilegeContext,
  UserWelcomeToast,
  SSO_REDIRECT_URL_PATH,
  LANDING_ROUTE_STORAGE_KEY,
  PageViewTracker,
} from '@percept/app-components'

import Routes from 'routes'

import { UserEnvironmentLoader } from './UserEnvironmentLoader'

import DateFnsUtils from '@date-io/date-fns'

import qs from 'query-string'

import { Sync } from '@percept/mui/icons'

import { noop, omit } from 'lodash-es'

import { registerServiceWorker } from '@percept/app-utils'

// import authenticatorBackground from '../../images/svg-patterns/login-bg.svg'
import authenticatorBackground from '../../images/backgrounds/login-bg.jpg'

import shortLogo from '../../images/logos/vodafone-logo-short.svg'

import {
  User as UserType,
  Notification,
  ApiResponse,
  RefreshResponse,
  UserPrivileges,
  Toast,
} from '@percept/types'

import {
  Location,
  History,
} from 'history'

import { Footer } from 'components/Footer'

import { ErrorScreen } from 'screens/ErrorScreen'


const reportPathnameRegex = /^\/series\/([0-9]+)\/reports\/.*/

const seriesPathnameMatchingIdRegex = /^\/series\/([0-9]+)/

const detailViewRegex = /^\/series\/([0-9]+)\/reports\/.*(metric_id=)/

const shouldScroll = (location: Location, previousLocation: Location): boolean => {
  const curr = location.pathname
  const prev = previousLocation.pathname

  const currSeriesMatch = seriesPathnameMatchingIdRegex.exec(curr)
  const prevSeriesMatch = seriesPathnameMatchingIdRegex.exec(prev)

  if( currSeriesMatch ){
    return !prevSeriesMatch || currSeriesMatch[1] !== prevSeriesMatch[1]
  }

  return (
    location.pathname !== previousLocation.pathname
    && !(detailViewRegex.test(curr) && detailViewRegex.test(prev))
    && !(reportPathnameRegex.test(curr) && reportPathnameRegex.test(prev))
  )
}


export type NavigationOptions = {
  replace?: boolean
  search?: Record<string, any>
}


type ReportErrorProps = {
  error: Error
  errorInfo: React.ErrorInfo
}

export type AppProps = {
  location: Location
  initialLocation: Location | null
  loggedIn: boolean
  history: History
  checkForUpdates: boolean
  updateInterval: number | null
  debug: boolean
  addNotification: (notification: Notification) => void
  addToast: (toast: Toast) => void
  reportError: (reportErrorProps: ReportErrorProps) => void
  navigate: (route: string, options?: NavigationOptions) => void
  refreshResponse: ApiResponse<RefreshResponse>
  ssoRefreshResponse: ApiResponse<RefreshResponse>
  userPrivileges: ApiResponse<UserPrivileges>
  checkRefreshToken: () => void
}

export type AppState = {
  loading: boolean
  user: UserType | null
  error: Error | null
  notification: Notification | null
}


const authenticatorStyleProps: Pick<AuthenticatorProps, 'BoxProps' | 'AuthInputProps'> = {
  BoxProps: {
    paddingTop: 3,
    style: {
      background: [
        'linear-gradient(0deg, rgba(13, 13, 13, 0.3) 0%, rgba(13, 13, 13, 0.3) 100%)',
        `url(${authenticatorBackground}) no-repeat center center fixed`,
        // 'linear-gradient(173.43deg, #880101 1.78%, #D40101 96.9%)',
      ].join(', '),
      // backgroundSize: 'cover, cover, cover',
      backgroundSize: 'cover, cover',
    },
    flexGrow: 1,
  },
  AuthInputProps: {
    variant: 'outlined',
    InputProps: {
      startAdornment: null,
    },
  },
}


const UnauthenticatedAppContainer = ({ children }: React.PropsWithChildren<{}>): JSX.Element => (
  <Box
    display='flex'
    flexDirection='column'
    height='100%'>
    <AppBar
      position='fixed'
      color='inherit'>
      <img
        src={shortLogo}
        style={{
          position: 'relative',
          top: 12,
          left: 12,
          height: 28,
          width: 28
        }} />
    </AppBar>
    { children }
    <Footer showApplicationLinks={false} />
  </Box>
)


class App extends Component<AppProps, AppState> {

  private hasInitializedServiceWorker: boolean

  private hasRedirected: boolean

  constructor(props: AppProps){
    super(props)
    this.state = {
      loading: true,
      user: null,
      error: null,
      notification: null,
    }
    this.hasInitializedServiceWorker = false
    this.hasRedirected = false
  }

  componentDidMount(): void {
    this.initializeServiceWorker()
    this.props.checkRefreshToken()

    setTimeout(() => {
      this.setState( prev => ({ ...prev, loading: false }))
    }, 50)
  }

  initializeServiceWorker(): void {

    if( this.hasInitializedServiceWorker ){
      return
    }

    const { checkForUpdates, updateInterval, debug } = this.props

    registerServiceWorker({
      updateInterval,
      debug,
      onUpdateError: null,
      onUpdateFound: !checkForUpdates ? null : (
        (onRefresh: () => void): void => {
          this.setState({
            notification: {
              id: 'refresh',
              type: 'info',
              alertIcon: <Sync />,
              name: 'Update Required',
              message: 'The application has been updated. Please click Update to install the latest version',
              buttonActions: [
                {
                  startIcon: <Sync />,
                  content: 'Update',
                  onClick: (): void => {
                    // Remove notification in case update is not found
                    this.setState({ notification: null })
                    // Call the provided refresher function
                    typeof onRefresh === 'function' && onRefresh()
                  },
                },
              ],
              onClose: (): void => this.setState({ notification: null })
            },
          })
        }
      ),
      onUpdateTrigger: !checkForUpdates ? null : (
        (): void => {
          this.setState({
            notification: {
              id: 'refresh',
              type: 'info',
              loading: true,
              name: 'Update In Progress',
              message: 'Application updating...',
              onClose: (): void => this.setState({ notification: null })
            }
          })
        }
      ),
      onUpdateSuccess: !checkForUpdates ? null : (
        (): void => {
          this.setState({
            notification: {
              id: 'refresh',
              type: 'success',
              name: 'Update Successful',
              message: 'Application updated.',
              ttl: 1500,
              onClose: (): void => this.setState({ notification: null })
            }
          })
        }
      )
    })

    this.hasInitializedServiceWorker = true

  }

  componentDidUpdate(): void {
    const { initialLocation, loggedIn } = this.props

    if( loggedIn && initialLocation && !this.hasRedirected ){
      if( [SSO_REDIRECT_URL_PATH, '/'].includes(initialLocation.pathname) ){
        let navigationPath = '/wizard/home'
        // Attempt to pick up landing route for SSO auth redirect
        if( initialLocation.pathname.includes(SSO_REDIRECT_URL_PATH) ){
          try{
            const originalReferrer = localStorage.getItem(LANDING_ROUTE_STORAGE_KEY)
            if( originalReferrer && !originalReferrer.includes(SSO_REDIRECT_URL_PATH) ){
              navigationPath = originalReferrer
            }
            localStorage.removeItem(LANDING_ROUTE_STORAGE_KEY)
          }catch(e){
            noop()
          }
        }
        this.props.navigate(navigationPath)
        this.hasRedirected = true
        return
      }
      if( initialLocation.pathname !== '/' ){
        this.props.navigate(initialLocation.pathname, {
          search: omit(
            qs.parse(initialLocation.search),
            ['username', 'token', 'code']
          ),
        })
        this.hasRedirected = true
        return
      }
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void{
    this.setState({ error })
    this.props.reportError({ error, errorInfo })
  }


  render(): JSX.Element {
    const { error, notification } = this.state,
          { location, loggedIn, refreshResponse, ssoRefreshResponse, userPrivileges, history } = this.props

    const loading = (
      this.state.loading
      || (refreshResponse.loading && !loggedIn)
      || (ssoRefreshResponse.loading && !loggedIn)
    )

    return (
      <AppThemeProvider
        theme={vodafoneAppTheme}>

        <Fragment>

          <GlobalCss />

          <CssBaseline />

          <ConnectedRouter
            history={history}>

            <ScrollToTop
              location={location}
              shouldScroll={shouldScroll}>

              { !loggedIn ? (
                <ErrorBoundary
                  FallbackComponent={ErrorScreen}
                  reportError={this.props.reportError}>
                  <UnauthenticatedAppContainer>
                    <Authenticator
                      isLoading={loading}
                      enableSingleSignOn
                      hideSignInHeader
                      singleSignOnLoadingContent={
                        <Typography variant='h2' style={{color: '#fff'}}>
                          Signing you in...
                        </Typography>
                      }
                      ButtonComponent={PlainTextButton}
                      logoContentOverride={
                        <Box position='absolute' px={4} pt={6}>
                          <Box fontSize={24} fontWeight={500}>
                            Log In
                          </Box>
                        </Box>
                      }
                      {...authenticatorStyleProps} />
                  </UnauthenticatedAppContainer>
                </ErrorBoundary>
              ) : (
                <Fragment>
                  <PageViewTracker />

                  <UserEnvironmentLoader />

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>

                    {(!userPrivileges.data || loading) ? (
                      <ErrorBoundary
                        FallbackComponent={ErrorScreen}
                        reportError={this.props.reportError}>
                        <UnauthenticatedAppContainer>
                          <Box height='100%' width='100%' {...authenticatorStyleProps.BoxProps} />
                          <BackdropLoader />
                        </UnauthenticatedAppContainer>
                      </ErrorBoundary>
                    ) : (
                      <UserPrivilegeContext.Provider value={userPrivileges.data}>
                        <UserWelcomeToast />
                        <ErrorBoundary
                          FallbackComponent={ErrorScreen}
                          reportError={this.props.reportError}>
                          <Routes />
                        </ErrorBoundary>
                      </UserPrivilegeContext.Provider>
                    )}
                  </MuiPickersUtilsProvider>
                </Fragment>
              ) }

            </ScrollToTop>

            <NotificationHub
              prepend={notification} />

            <ToastHub />

          </ConnectedRouter>

        </Fragment>

      </AppThemeProvider>
    )
  }

}


export default App
