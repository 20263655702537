import React, { useContext, useEffect, useMemo } from 'react'

import {
  Alert,
  BackdropLoader,
  Box,
  ButtonProps,
  ProviderLogo,
  ReportRange,
  RoundedPlainTextButton,
  RoundedPlainTextButtonMenu,
} from '@percept/mui'

import { ArrowDropDown, OpenInNew } from '@percept/mui/icons'

import { Link } from 'react-router-dom'

import Series from './Series'

import { PillarScores } from './PillarScores'
import { PillarScoresDownload } from './PillarScoresDownload'

import { InsightsReport } from 'components/InsightsReport'
import { defaultInsightUrlParams, InsightContext, InsightsByImportance, InsightUrlParams } from '@percept/app-components'

import {
  stringifyUrlSearchParams,
  useDocumentTitle,
  useInsightUnitsByImportance,
  useLatestInsightUnitsByImportance,
  useReportSeries,
  useReportSeriesPillarScores,
  useReportSeriesReports,
  useUrlState,
} from '@percept/hooks'

import { useActivePlatformUnit } from 'components/Organisation'

import { get, some, sortBy } from 'lodash-es'

import { ReportProviderV2, ReportSeries, SeriesParams, StructuralReport } from '@percept/types'
import { providerLabelMap } from '@percept/constants'
import { useSavedQueryStrings } from 'hooks'


type SeriesInsightsViewProps = (
  SeriesParams
  & {
    provider: ReportProviderV2
  }
)


const SeriesWithInsightsByImportance = ({
  series_id,
  provider,
}: SeriesInsightsViewProps): JSX.Element => {

  const activePlatformUnit = useActivePlatformUnit()

  const [reports] = useReportSeriesReports({ series_id })
  const reportsWithResults = sortBy(
    reports.data && reports.data.filter( r => !!r.results.length ),
    'end'
  ).reverse()

  const [state, setState] = useContext(InsightContext)

  const latestReportId = get(reportsWithResults, [0, 'id'], null)

  const showLatestReport = state.report === 'latest'

  const reportId = showLatestReport ? latestReportId : state.report// || get(reportsWithResults, [0, 'id'], null)

  const latestInsightsByImportance = useLatestInsightUnitsByImportance({
    series_id,
    enabled: showLatestReport,
  })

  const historicInsightsByImportance = useInsightUnitsByImportance({
    series_id,
    report_id: reportId,
    enabled: !showLatestReport,
  })


  const activeReport = reportsWithResults.find( r => r.id === reportId )

  const selectLabel = activeReport && (
    <ReportRange
      disableTypography
      start={activeReport.start}
      end={activeReport.end} />
  )

  const buttonProps: ButtonProps = {
    variant: 'contained',
    color: 'secondary',
    size: 'small',
  }

  const secondaryTitleContent = (
    reportsWithResults.length === 1 ? (
      <RoundedPlainTextButton
        {...buttonProps}>
        { selectLabel }
      </RoundedPlainTextButton>
    ) : reportsWithResults.length ? (
      <RoundedPlainTextButtonMenu
        TriggerProps={{
          ...buttonProps,
          endIcon: <ArrowDropDown />,
        }}
        value={reportId}
        label={selectLabel}
        options={
          reportsWithResults.map( r => ({
            value: r.id,
            label: <ReportRange disableTypography start={r.start} end={r.end} />
          }))
        }
        onChange={(e, value): void => {
          setState({ report: value })
        }} />
    ) : null
  )

  const activeQuery = !showLatestReport ? historicInsightsByImportance : latestInsightsByImportance

  return (
    <Box p={3} pt={5}>
      { activeQuery.error ? (
        <Alert variant='info' p={3}>
          Insights could not be loaded
        </Alert>
      ) : !activeQuery.data ? (
        <BackdropLoader />
      ) : (
        <InsightsByImportance
          series_id={series_id}
          report_id={reportId}
          provider={provider}
          enableFavourites
          platformUnit={activePlatformUnit}
          insightsByImportance={activeQuery.data}
          secondaryTitleContent={secondaryTitleContent}
          titleContentRight={
            reportId && (
              <Link
                to={`/series/${series_id}/reports/${reportId}`}>
                <RoundedPlainTextButton
                  variant='contained'
                  size='small'
                  color='secondary'
                  startIcon={<OpenInNew />}>
                  View Detailed Report
                </RoundedPlainTextButton>
              </Link>
            )
          } />
      )}
    </Box>
  )
}


const SeriesWithInsights = ({
  series_id,
  provider,
}: SeriesInsightsViewProps): JSX.Element => {

  const [pillarScores] = useReportSeriesPillarScores({ series_id })

  const [reports] = useReportSeriesReports({ series_id })
  const reportsWithResults = sortBy(
    reports.data && reports.data.filter( r => !!r.results.length ),
    'end'
  ).reverse()

  const [state, setState] = useContext(InsightContext)

  const latestReportId = get(reportsWithResults, [0, 'id'], null)

  const showLatestReport = state.report === 'latest'

  const reportId = showLatestReport ? latestReportId : state.report

  const platformUnit = useActivePlatformUnit()

  return (
    <InsightsReport
      key={series_id}
      series_id={series_id}
      title={
        <ProviderLogo
          size={1.75}
          units='rem'
          provider={provider} />
      }
      activeReportId={reportId}
      setActiveReportId={(value): void => {
        setState({ report: value })
      }}
      insights_report_type='primary'
      showReportLink={true}
      showSubTypeOptions={true}
      gridInserts={
        (pillarScores.data && platformUnit) ? [
          <>
            <Box
              display='flex'
              mb={2}
              justifyContent='end'>
              <PillarScoresDownload
                pillarScores={pillarScores.data}
                filename={
                  [
                    platformUnit.name,
                    providerLabelMap[provider],
                    'Pillar Scores',
                  ].join(' - ')
                }
                provider={provider} />

            </Box>
            <PillarScores
              key={series_id}
              provider={provider}
              scores={pillarScores.data} />
          </>
        ] : undefined
      } />
  )
}


const hasPrimaryInsightReports = (
  series: ReportSeries,
  reports: StructuralReport[]
): boolean => {
  const latestReport = sortBy(reports.filter( r => !!r.results.length), 'end').reverse()[0]
  return Boolean(
    series.output_config.insights_reports
    && series.output_config.insights_reports.PRIMARY
    && latestReport
    && some(latestReport.results, res => res.result_type === 'INSIGHTS_REPORT' && res.result_subtype === 'PRIMARY' )
  )
}


export const SeriesViewManager = ({ series_id }: SeriesParams): JSX.Element => {

  const platformUnit = useActivePlatformUnit()

  const [series] = useReportSeries({ series_id })

  const [reports] = useReportSeriesReports({ series_id })

  const urlStateHookValue = useUrlState<InsightUrlParams>(defaultInsightUrlParams)

  const [state, setState] = urlStateHookValue

  const [, setSavedQueryStrings] = useSavedQueryStrings()

  useEffect(() => {
    // NOTE - currently we only store the view parameter which applies to insight reports
    // only.
    setSavedQueryStrings({
      series: stringifyUrlSearchParams({ view: state.view })
    })
  }, [state.view, setSavedQueryStrings])

  useDocumentTitle({
    paths: [
      get(platformUnit, 'name'),
      get(series.data, ['provider', 'name']),
    ]
  })

  const report_id = state.report === 'latest' ? null : state.report

  const latestInsightsByImportance = useLatestInsightUnitsByImportance({
    series_id,
    enabled: !report_id,
  })

  const historicInsightsByImportance = useInsightUnitsByImportance({
    series_id,
    report_id,
    enabled: !!report_id,
  })

  const activeByImportanceQuery = report_id ? historicInsightsByImportance : latestInsightsByImportance

  useEffect(() => {
    if( reports.data && report_id ){
      const match = reports.data.find( r => r.id === report_id )
      if( !match ){
        setState({ report: 'latest' })
      }
    }
  }, [reports.data, report_id, setState])

  const hasInsights = useMemo(() => {
    if( !(series.data && reports.data) ){
      return null
    }
    return hasPrimaryInsightReports(series.data, reports.data)
  }, [series.data, reports.data])

  if( activeByImportanceQuery.data && series.data ){
    return (
      <InsightContext.Provider value={urlStateHookValue}>
        <SeriesWithInsightsByImportance
          key={series_id}
          series_id={series_id}
          provider={series.data.provider.slug} />
      </InsightContext.Provider>
    )
  }

  if( !(series.data && reports.data) || activeByImportanceQuery.isLoading ){
    return <BackdropLoader />
  }

  if( hasInsights && activeByImportanceQuery.error ){
    return (
      <InsightContext.Provider value={urlStateHookValue}>
        <SeriesWithInsights
          key={series_id}
          series_id={series_id}
          provider={series.data.provider.slug} />
      </InsightContext.Provider>
    )
  }

  return (
    <Series series_id={series_id} />
  )
}
