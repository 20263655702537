import React, { useMemo } from 'react'

import {
  Box,
  Card,
  dateRangeCalculators,
  DateRangePresetOption,
  Loader,
  RoundedPlainTextButtonMenu,
  SimpleTable,
  Typography,
  makeAppStyles,
  LinearProgress,
  useAppTheme,
  Grid,
  RoundedPlainTextButton,
  CircularProgress,
  DateRangePreset,
  MenuOption,
  FilterPanel,
  FormLabel,
  DateRangePresetInput,
  DateRangeValue,
  resolveDateRangePresetLabel,
  CheckboxGroup,
  RadioFormGroup,
} from '@percept/mui'

import { ArrowDropDown, CloudDownload, Help, Language } from '@percept/mui/icons'

import { capitalize, get, intersection, pick, some } from 'lodash-es'

import {
  getPrimaryApplicationLabel,
  getSecondaryApplicationLabel,
  PrimaryApplication,
  SecondaryApplication,
  useTenantMarketUserEvents,
  useTenantMarketUserEventsCSVExport,
} from '@percept/app-components'

import { MarketDisplayLabel } from 'components/MarketDisplay'

import { dmyFormatter, LegendItem, MultiLineProps, numberFormatter, ResponsiveMultiLine } from '@percept/mui/charts'

import { VODAFONE_GLOBAL_ID, VodafoneMarket, vodafoneMarkets } from 'vodafoneMarkets'

import { useUrlState } from '@percept/hooks'

import {
  analyticsTableColumns,
  AnalyticsTableRow,
  filteredPrimaryApplications,
  filteredSecondaryApplications,
  getAnalyticsTimeseriesDatasets,
  getCombinedApplicationTimeseriesDataset,
  getMultiDatasetDefs,
  getPrimaryTableRows,
  getSecondaryTableRowMapping,
  globalMarketObject,
  unknownMarketObject,
  ViewType,
} from './lib'

import { deslugify, isoDate, produceKeyedMapping } from '@percept/utils'

import { getAvailableGranularities, getMaximumGranularity, getRequiredGranularity, ViewSegmentation } from './utils'

import { Dictionary, TimeseriesGranularity } from '@percept/types'


const useStyles = makeAppStyles( theme => ({
  title: {
    marginRight: theme.spacing(3),
  },
  filterCard: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  selector: {
    marginRight: theme.spacing(2),
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  inlineSpacedFragment: {
    marginRight: theme.spacing(1),
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  chartCard: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(2),
    position: 'relative',
  },
  chartContainer: {
    margin: theme.spacing(2, 2, 1, 2),
  },
  tableContainer: {
    position: 'relative',
  },
  refetchIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 4,
    zIndex: theme.zIndex.appBar,
  },
}))


const CHART_PROPS: Partial<MultiLineProps> = {
  height: 300,
  xScaleType: 'time',
  roundXDomain: false,
  grid: 'rows',
  axisText: true,
  yTickFormatter: numberFormatter,
  xTickFormatter: dmyFormatter,
  tooltipStyleOverrides: {
    zIndex: 9999,
  }
}

const maxDate = new Date()

const analyticsDateRangePresetOptions: DateRangePresetOption[] = [
  'today',
  'yesterday',
  'last-7-days',
  'last-30-days',
  'last-week',
  'last-month',
  'last-quarter',
  'current-year',
  'last-year',
]

const defaultPresetGranularities: Partial<Record<DateRangePreset, TimeseriesGranularity>> = {
  'current-financial-year': 'MONTH',
  'current-year': 'MONTH',
  'last-financial-year': 'MONTH',
  'last-year': 'MONTH',
  'last-quarter': 'MONTH',
  'last-month': 'WEEK',
  'last-30-days': 'WEEK',
  'last-7-days': 'DAY',
  'last-week': 'DAY',
  'today': 'DAY',
  'yesterday': 'DAY',
}

const defaultDateRangePreset: DateRangePresetOption = 'last-7-days'

const granularityLabels: Record<TimeseriesGranularity, string> = {
  DAY: 'Daily',
  WEEK: 'Weekly',
  MONTH: 'Monthly',
  QUARTER: 'Quarterly',
  CALENDAR_YEAR: 'By Calendar Year',
  FINANCIAL_YEAR: 'By Financial Year',
}

export const marketOptions: (
  VodafoneMarket & {
    labelOverride?: JSX.Element | null
  }
)[] = [
  {
    ...globalMarketObject,
    labelOverride: (
      <Box display='flex' alignItems='center'>
        <Language style={{fontSize: 18, marginRight: 4, marginLeft: -2}} />
        Global
      </Box>
    ),
  },
  ...vodafoneMarkets,
  {
    ...unknownMarketObject,
    labelOverride: (
      <Box display='flex' alignItems='center'>
        <Help style={{fontSize: 18, marginRight: 4, marginLeft: -2}} />
        Unknown
      </Box>
    ),
  },
]


const marketOptionsBySlug = produceKeyedMapping(marketOptions, 'slug')


type AnalyticsState = {
  markets: string[]
  primaryApplications: PrimaryApplication[]
  secondaryApplications: SecondaryApplication[]
  viewType: ViewType
  viewSegmentation: ViewSegmentation
  dateRangePreset: DateRangePresetOption
  start?: string | null
  end?: string | null
  granularity?: TimeseriesGranularity | null
}

const defaultAnalyticsState: AnalyticsState = {
  markets: marketOptions.map( m => m.slug ),
  primaryApplications: filteredPrimaryApplications,
  secondaryApplications: filteredSecondaryApplications,
  viewType: 'USERS',
  viewSegmentation: 'MARKET',
  dateRangePreset: defaultDateRangePreset,
  start: undefined,
  end: undefined,
  granularity: 'DAY',
}

type FilterPanelState = Omit<AnalyticsState, 'start' | 'end' | 'primaryApplications' | 'secondaryApplications'> & {
  dateRange: {
    preset: DateRangePresetOption
    value: [Date | null, Date | null]
  }
  applications: (PrimaryApplication | SecondaryApplication)[]
}

const getNextParams = (searchParams: Dictionary, values: AnalyticsState): AnalyticsState => {
  const defaultValueKeys = Object.keys(values)
  const scopedParams = pick(searchParams, defaultValueKeys) as Partial<AnalyticsState>
  if( !scopedParams.primaryApplications && !scopedParams.secondaryApplications ){
    return {
      ...values,
      ...scopedParams,
    }
  }
  return {
    ...values,
    ...scopedParams,
    // We need to set these here as otherwise the default values will be injected when
    // there are no apps selected for either type, due to URL params omitting the keys
    // when the values are empty lists
    primaryApplications: scopedParams.primaryApplications || [],
    secondaryApplications: scopedParams.secondaryApplications || [],
  }
}

const stateToFilterState = ({
  primaryApplications,
  secondaryApplications,
  ...state
}: AnalyticsState): FilterPanelState => {
  return ({
    ...state,
    applications: [
      ...(primaryApplications || []),
      ...(secondaryApplications || []),
    ].sort(),
    dateRange: {
      preset: state.dateRangePreset || 'custom',
      value: [
        state.start ? new Date(state.start) : null,
        state.end ? new Date(state.end) : null,
      ],
    }
  })
}

export const Analytics = (): JSX.Element => {
 
  const [state, setState] = useUrlState<AnalyticsState>(defaultAnalyticsState, { getNextParams })

  const dateRange: [Date, Date] = (
    state.dateRangePreset !== 'custom' ?
      dateRangeCalculators[state.dateRangePreset](maxDate) :
      state.start && state.end ?
        [new Date(state.start), new Date(state.end)] :
        [maxDate, maxDate]
  )

  const {
    viewType,
    viewSegmentation,
    markets,
    primaryApplications,
    secondaryApplications,
  } = state

  const granularity = state.granularity || getMaximumGranularity(dateRange) || 'FINANCIAL_YEAR'

  const baseHookParams: {
    start: Date
    end: Date
    market_slugs: string[] | null
  } = {
    start: dateRange[0],
    end: dateRange[1],
    market_slugs: markets,
  }

  const primaryQueryResult = useTenantMarketUserEvents({
    tenant_id: VODAFONE_GLOBAL_ID,
    ...baseHookParams,
    segments: ['MARKET', 'PRIMARY_APPLICATION'],
    primary_applications: primaryApplications,
    secondary_applications: secondaryApplications,
  })

  const secondaryQueryResult = useTenantMarketUserEvents({
    tenant_id: VODAFONE_GLOBAL_ID,
    ...baseHookParams,
    segments: ['MARKET', 'SECONDARY_APPLICATION'],
    secondary_applications: secondaryApplications,
  })

  const marketTimeseriesQueryResult = useTenantMarketUserEvents({
    tenant_id: VODAFONE_GLOBAL_ID,
    ...baseHookParams,
    segments: ['DATE', 'MARKET'],
    granularity,
    primary_applications: primaryApplications,
    secondary_applications: secondaryApplications,
  })

  const primaryApplicationTimeseriesQueryResult = useTenantMarketUserEvents({
    tenant_id: VODAFONE_GLOBAL_ID,
    ...baseHookParams,
    segments: ['DATE', 'PRIMARY_APPLICATION'],
    granularity,
    primary_applications: primaryApplications,
  })

  const secondaryApplicationTimeseriesQueryResult = useTenantMarketUserEvents({
    tenant_id: VODAFONE_GLOBAL_ID,
    ...baseHookParams,
    segments: ['DATE', 'SECONDARY_APPLICATION'],
    granularity,
    secondary_applications: secondaryApplications,
  })

  const exportHook = useTenantMarketUserEventsCSVExport()

  /* Table data */

  const primaryRows = useMemo(() => {
    if( !primaryQueryResult.data ){
      return []
    }
    return getPrimaryTableRows(primaryQueryResult.data, viewType)
  }, [primaryQueryResult.data, viewType])

  const secondaryRowMapping = useMemo(() => {
    if( !secondaryQueryResult.data ){
      return {}
    }
    return getSecondaryTableRowMapping(secondaryQueryResult.data, viewType)
  }, [secondaryQueryResult.data, viewType])

  /* Timeseries data */

  const appTheme = useAppTheme()

  const timeseries = useMemo(() => {
    if(
      !marketTimeseriesQueryResult.data
      || !primaryApplicationTimeseriesQueryResult.data
      || !secondaryApplicationTimeseriesQueryResult.data
    ){
      return null
    }
    return {
      market: getAnalyticsTimeseriesDatasets({
        userEvents: marketTimeseriesQueryResult.data,
        dateRange,
        granularity,
        viewType,
        appTheme,
        groupBy: 'MARKET',
      }),
      combinedApplication: getCombinedApplicationTimeseriesDataset({
        primary: primaryApplicationTimeseriesQueryResult.data,
        secondary: secondaryApplicationTimeseriesQueryResult.data,
        primaryApplicationFilter: primaryApplications,
        secondaryApplicationFilter: secondaryApplications,
        dateRange,
        granularity,
        viewType,
        appTheme,
      }),
    }
  }, [
    marketTimeseriesQueryResult.data,
    primaryApplicationTimeseriesQueryResult.data,
    secondaryApplicationTimeseriesQueryResult.data,
    dateRange,
    granularity,
    viewType,
    appTheme,
    primaryApplications,
    secondaryApplications,
  ])

  const timeseriesHooks = [
    marketTimeseriesQueryResult,
    primaryApplicationTimeseriesQueryResult,
    secondaryApplicationTimeseriesQueryResult,
  ]

  const isTimeseriesLoading = some(timeseriesHooks, q => q.isLoading)

  const isTimeseriesError = some(timeseriesHooks, q => q.isError)

  const getRowGroup = (row: AnalyticsTableRow): AnalyticsTableRow[] => (
    secondaryRowMapping[row.label] || []
  )

  const viewTypeLabel = capitalize(viewType)

  const appTimeseriesDataSignal = get(timeseries, ['combinedApplication', 0, 'data', 'length'], 0)
  const marketTimeseriesDataSignal = get(timeseries, ['market', 0, 'data', 'length'], 0)

  const timeseriesDataSignals = [
    appTimeseriesDataSignal,
    marketTimeseriesDataSignal,
  ]

  const hasAnyTimeseriesData = some(timeseriesDataSignals)

  const numTicks = Number(
    timeseries && Math.min(
      Math.max(...timeseriesDataSignals),
      10
    )
  )

  const chartIsRefetching = (
    viewSegmentation === 'MARKET' ?
      marketTimeseriesQueryResult.isRefetching :
      primaryApplicationTimeseriesQueryResult.isRefetching
  )

  const chartDatasets = (
    viewSegmentation === 'MARKET' ?
      timeseries && timeseries.market :
      timeseries && timeseries.combinedApplication
  )

  const chartDatasetSignal = get(chartDatasets, [0, 'data', 'length'], 0)

  const classes = useStyles()

  return (
    <Box p={3}>

      <Typography variant='h2' className={classes.title}>
        Analytics
      </Typography>

      <Card className={classes.filterCard}>
        <FilterPanel
          values={stateToFilterState(state)}
          onConfirm={({ dateRange, applications, ...values }): void => {
            const primaryApplications = intersection(filteredPrimaryApplications, applications) as PrimaryApplication[]
            const secondaryApplications = intersection(filteredSecondaryApplications, applications) as SecondaryApplication[]
            setState({
              ...values,
              primaryApplications,
              secondaryApplications,
              dateRangePreset: dateRange.preset,
              start: dateRange.value[0] ? isoDate(dateRange.value[0]) : undefined,
              end: dateRange.value[1] ? isoDate(dateRange.value[1]) : undefined,
            })
          }}
          valueOrder={[
            'dateRange',
            'granularity',
            'markets',
            'applications',
            'viewType',
          ]}
          displayConfig={{
            dateRange: {
              label: 'Date Range',
              nullable: false,
              render: ({ preset, value }): React.ReactNode => (
                resolveDateRangePresetLabel(preset, value as DateRangeValue)
              ),
            },
            granularity: {
              label: 'Granularity',
              nullable: false,
              render: (value): string => granularityLabels[value],
            },
            markets: {
              label: 'Markets',
              render: (value): React.ReactNode => {
                const marketOption = marketOptionsBySlug[value]
                return marketOption.labelOverride || <MarketDisplayLabel {...marketOption} />
              },
            },
            applications: {
              label: 'Applications',
              render: (value): string => deslugify(value),
            },
            viewType: {
              label: 'View Type',
              nullable: false,
              render: (value): string => capitalize(value),
            },
          }}>
          { ({ values, updateValues }): JSX.Element => (
            <Grid container spacing={5}>
              <Grid item xs='auto'>
                <FormLabel>Date Range</FormLabel>
                <DateRangePresetInput
                  BoxProps={{
                    mt: 1,
                  }}
                  variant='static'
                  divided
                  color='secondary'
                  dateRangePreset={values.dateRange.preset}
                  dateRangePresetOptions={analyticsDateRangePresetOptions}
                  value={values.dateRange.value as DateRangeValue}
                  maxDate={maxDate}
                  onChange={(value, preset): void => {
                    const update: Partial<FilterPanelState> = {
                      dateRange: {
                        preset,
                        value,
                      }
                    }
                    if( preset !== 'custom' ){
                      update.granularity = defaultPresetGranularities[preset]
                    }else{
                      update.granularity = getRequiredGranularity(dateRange, granularity)
                    }
                    updateValues(update)
                  }} />
              </Grid>

              <Grid item xs='auto'>
                <RadioFormGroup
                  name='Granularity'
                  value={values.granularity}
                  options={
                    (values.dateRange.value[0] && values.dateRange.value[1]) ?
                      getAvailableGranularities(values.dateRange.value as [Date, Date]).map( value => ({
                        value,
                        label: granularityLabels[value],
                      })) :
                        [{value: 'DAY', label: granularityLabels.DAY}] as MenuOption<TimeseriesGranularity>[]
                  }
                  onChange={(granularity): void => {
                    updateValues({ granularity })
                  }} />
              </Grid>

              <Grid item xs='auto'>
                <CheckboxGroup
                  name='Markets'
                  value={values.markets}
                  options={
                    marketOptions.map( option => ({
                      value: option.slug,
                      label: option.labelOverride || <MarketDisplayLabel {...option} />,
                    }))
                  }
                  onChange={(value): void => {
                    updateValues({ markets: value })
                  }} />
              </Grid>

              <Grid item xs='auto'>
                <CheckboxGroup
                  name='Applications'
                  value={values.applications}
                  options={
                    [
                      ...filteredPrimaryApplications.map( value => ({
                        value,
                        label: getPrimaryApplicationLabel(value),
                      })),
                      ...filteredSecondaryApplications.map( value => ({
                        value,
                        label: getSecondaryApplicationLabel(value),
                      }))
                    ]
                  }
                  onChange={(applications): void => {
                    updateValues({ applications })
                  }} />
              </Grid>

              <Grid item xs='auto'>
                <RadioFormGroup
                  name='View Type'
                  value={values.viewType}
                  options={[
                    { value: 'USERS', label: 'Users' },
                    { value: 'VIEWS', label: 'Views' },
                  ] as MenuOption<ViewType>[]}
                  onChange={(viewType): void => {
                    updateValues({ viewType })
                  }} />
              </Grid>
            </Grid>
          )}
        </FilterPanel>
      </Card>

      { isTimeseriesError ? (
        <Box my={10} display='flex' justifyContent='center'>
          <Typography variant='h3' color='textSecondary'>
            An error occurred and timeseries data could not be loaded
          </Typography>
        </Box>
      ) : isTimeseriesLoading ? (
        <Loader preset='fullsize' minHeight={CHART_PROPS.height} />
      ) : timeseries && hasAnyTimeseriesData && chartDatasets && (
        <Box mt={3} mb={5}>
          <Grid container spacing={4}>
            <Grid item xs={12} className={classes.gridItem}>
              <Card className={classes.chartCard}>
                { chartIsRefetching && (
                  <LinearProgress className={classes.refetchIndicator} />
                ) }
                <Typography variant='h5'>
                  <span className={classes.inlineSpacedFragment}>{viewTypeLabel} over time by</span>
                  <RoundedPlainTextButtonMenu
                    TriggerProps={{
                      variant: 'contained',
                      size: 'small',
                      endIcon: <ArrowDropDown />,
                    }}
                    value={viewSegmentation}
                    label={capitalize(viewSegmentation)}
                    options={[
                      {value: 'MARKET', label: 'Market'},
                      {value: 'APPLICATION', label: 'Application'},
                    ] as MenuOption<ViewSegmentation>[]}
                    onChange={(e, value): void => {
                      setState( prev => ({ ...prev, viewSegmentation: value }))
                    }} />
                </Typography>
                <div className={classes.chartContainer}>
                  <ResponsiveMultiLine
                    {...CHART_PROPS}
                    defs={getMultiDatasetDefs(chartDatasets)}
                    numXTicks={chartDatasetSignal ? numTicks : 0}
                    datasets={chartDatasets} />
                </div>

                <Box display='flex' alignItems='center' flexWrap='wrap'>
                  { chartDatasets.map( d => (
                    <LegendItem
                      key={d.key}
                      datum={d} />
                  ))}
                </Box>
              </Card>
            </Grid>
            {/* <Grid item xs={6} className={classes.gridItem}>
              <Card className={classes.chartCard}>
                { (marketTimeseriesQueryResult.isRefetching) && (
                  <LinearProgress className={classes.refetchIndicator} />
                ) }
                <Typography variant='h5'>
                  {viewTypeLabel} over time by market
                </Typography>
                <div className={classes.chartContainer}>
                  <ResponsiveMultiLine
                    {...CHART_PROPS}
                    defs={getMultiDatasetDefs(timeseries.market)}
                    numXTicks={marketTimeseriesDataSignal ? numTicks : 0}
                    datasets={timeseries.market} />
                </div>

                <Box display='flex' alignItems='center' flexWrap='wrap'>
                  { timeseries.market.map( d => (
                    <LegendItem
                      key={d.key}
                      datum={d} />
                  ))}
                </Box>
              </Card>
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <Card className={classes.chartCard}>
                { (primaryApplicationTimeseriesQueryResult.isRefetching) && (
                  <LinearProgress className={classes.refetchIndicator} />
                ) }
                <Typography variant='h5'>
                  {viewTypeLabel} over time by section
                </Typography>
                <div className={classes.chartContainer}>
                  <ResponsiveMultiLine
                    {...CHART_PROPS}
                    defs={getMultiDatasetDefs(timeseries.combinedApplication)}
                    numXTicks={appTimeseriesDataSignal ? numTicks : 0}
                    datasets={timeseries.combinedApplication} />
                </div>

                <Box display='flex' alignItems='center' flexWrap='wrap'>
                  { timeseries.combinedApplication.map( d => (
                    <LegendItem
                      key={d.key}
                      datum={d} />
                  ))}
                </Box>
              </Card>
            </Grid> */}
          </Grid>
        </Box>
      )}
      
      { (primaryQueryResult.error || secondaryQueryResult.error) ? (
        <Box my={10} display='flex' justifyContent='center'>
          <Typography variant='h3' color='textSecondary'>
            An error occurred and the data could not be loaded
          </Typography>
        </Box>
      ) : !(primaryQueryResult.data && secondaryQueryResult.data) ? (
        <Loader preset='fullsize' minHeight='15rem' />
      ) : !primaryRows.length ? (
        <Box my={10} display='flex' justifyContent='center'>
          <Typography variant='h3' color='textSecondary'>
            No results for selected time period
          </Typography>
        </Box>
      ) : (
        <>
          <Box display='flex' justifyContent='flex-end' mb={3}>
            <RoundedPlainTextButton
              variant='contained'
              color='secondary'
              size='small'
              disabled={exportHook.isLoading}
              startIcon={
                exportHook.isLoading ?
                  <CircularProgress size='1em' color='inherit' /> :
                  <CloudDownload />
              }
              onClick={(): void => {
                exportHook.mutate({
                  tenant_id: VODAFONE_GLOBAL_ID,
                  ...baseHookParams,
                  primary_applications: primaryApplications,
                  secondary_applications: secondaryApplications,
                  cardinality_type: viewType,
                })
              }}>
              Download CSV
            </RoundedPlainTextButton>
          </Box>

          <Card className={classes.tableContainer}>
            <SimpleTable
              size='small'
              sortable
              pinFirstColumn
              stickyHeader
              unsetStickyHeaderZIndex={false}
              wrapCellText={false}
              grouped={true}
              getRowGroup={getRowGroup}
              columns={analyticsTableColumns}
              rows={primaryRows} />
            { (primaryQueryResult.isRefetching || secondaryQueryResult.isRefetching) && (
              <LinearProgress className={classes.refetchIndicator} />
            ) }
          </Card>
        </>
      )}
    </Box>
  )
}
