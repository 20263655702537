import React from 'react'
import {
  ChartData,
  MultiDataset,
  ResponsiveHistogramWithTooltip,
  ResponsiveMultiLine,
  ResponsiveStackedHistogramWithTooltip,
  SVGDatumType,
  financialQuarterFormatter,
  financialYearFormatter,
  getMoneyFormatter,
  longMonthYearFormatter,
  percentageFormatter,
} from '@percept/mui/charts'
import { CHART_HEIGHT } from './constants'
import { CellItem, ListItem } from '../types'
import { AppTheme, Box, useAppTheme } from '@percept/mui'
import { get, isFunction, sortBy } from 'lodash-es'
import { addMonths, addQuarters, addYears, parse, subDays } from 'date-fns'
import { Dictionary, Nullable } from '@percept/types'
import { CompetitiveGranularity } from 'api/services/CompetitiveReports'


const getExclusionConfigPredicate = (
  exclusionConfig: string[] | ((dimension: string) => boolean)
): ((dimension: string) => boolean) => {
  if( isFunction(exclusionConfig) ){
    return exclusionConfig
  }
  return (segment) => !exclusionConfig.includes(segment)
}

const filterListItems = (
  data: ListItem[],
  { rowFilter, columnFilter, includeTotals = false, segmentDimensionExclusions }: {
    rowFilter: string | null
    columnFilter: string | null
    includeTotals?: boolean
    segmentDimensionExclusions?: Record<string, string[] | ((dimension: string) => boolean)>
  }
): ListItem[] => {
  let filteredData = data

  if( !includeTotals ){
    filteredData = data.filter( r => !r.isTotalRow )
  }

  if( rowFilter ){
    filteredData = filteredData.filter( r => r.row_group === rowFilter )
    const dimensionExclusions = get(segmentDimensionExclusions, rowFilter)
    if( dimensionExclusions ){
      const predicate = getExclusionConfigPredicate(dimensionExclusions)
      filteredData = filteredData.map( r => ({
        ...r,
        data: r.data && r.data.map( d => ({
          ...d,
          costs: d.costs && d.costs.filter( c => predicate(c.type_value) ),
        })),
        costs: r.costs.filter( c => predicate(c.type_value)),
      }))
    }
  }

  if( columnFilter ){
    filteredData = filteredData.map( r => ({
      ...r,
      costs: rowFilter && r.data ? (
        r.data.map( d => {
          const matchingCost = d.costs.filter( c => c.type_value === columnFilter )[0]
          return {
            type_value: d.data_type,
            spend: get(matchingCost, 'spend', '0'),
            percent: get(matchingCost, 'percent', '0'),
          } as CellItem
        })
      ) : (
        r.costs.filter( c => c.type_value === columnFilter )
      ),
    }))
  }

  return filteredData
}

type ChartDataConfiguration = {
  isTimeline?: boolean
  deriveTimelineFormatter?: boolean
  ignoreKeys?: string[]
  domainPositions?: [number, string][]
  segmentDimensionExclusions?: Record<string, string[] | ((dimension: string) => boolean)>
}

type BaseChartProps = {
  data: ListItem[]
  dataFormat: string
  appTheme: AppTheme
}

type InvestmentReportChartDataAdapterProps = BaseChartProps & ChartDataConfiguration


type InvestmentReportChartDataAdapter<C extends (ChartData | MultiDataset[])> = (
  props: InvestmentReportChartDataAdapterProps
) => C


export type InvestmentReportChartConfiguration<C extends (ChartData | MultiDataset[]) = (ChartData | MultiDataset[])> = {
  adapter: InvestmentReportChartDataAdapter<C>
  dataConfiguration?: ChartDataConfiguration
  componentProps?: Record<string, any>
  includeTotals?: boolean
  granularity?: CompetitiveGranularity
}

export type ChartComponentOverrideProps = (
  & Omit<BaseChartProps, 'appTheme'>
  & InvestmentReportChartConfiguration
  & Nullable<{ columnFilter: string, rowFilter: string }>
)


const ensureDomainPositions = (domain: string[], ...positions: [number, string][]): string[] => {
  domain = domain.slice()
  for( const [index, key] of positions ){
    const currentIndex = domain.indexOf(key)
    if( currentIndex > -1 && currentIndex !== index ){
      domain.splice(currentIndex, 1)
      domain.splice(index, 0, key)
    }
  }
  return domain
}

const resolveDomain = ({
  domain,
  domainPositions = [],
  ignoreKeys = [],
}: {
  domain: string[]
} & Pick<ChartDataConfiguration, 'ignoreKeys' | 'domainPositions'>): string[] => {
  if( ignoreKeys.length ){
    domain = domain.filter( d => !ignoreKeys.includes(d) )
  }
  domain = ensureDomainPositions(domain, ...domainPositions)
  return domain
}


export const listItemsToChartData = ({
  data,
  dataFormat,
  appTheme,
  domainPositions,
  ignoreKeys,
}: InvestmentReportChartDataAdapterProps): ChartData => {
  const sourceDomain = (
    data.length === 1 ?
      data[0].costs.map( c => c.type_value ) :
      data.map( d => d.row_group )
  )
  const domain = resolveDomain({ domain: sourceDomain, domainPositions, ignoreKeys })
  const colorScale = appTheme.chart.getOrdinalColourScale(domain)
  if( data.length === 1 ){
    return data[0].costs.map( c => ({
      label: c.type_value,
      value: Number(dataFormat === 'currency' ? c.spend : c.percent),
      color: colorScale(c.type_value),
    }))
  }
  return data.map( d => {
    const matchingCost = d.costs[0]
    return {
      label: d.row_group,
      value: Number(
        dataFormat === 'currency' ?
          get(matchingCost, 'spend', '0') :
          get(matchingCost, 'percent', '0')
      ),
      color: colorScale(d.row_group),
    }
  })
}


const inferPeriodEnd = (d: CellItem, date: Date): Date => {
  const dateString = d.type_value
  let addMethod = addMonths
  if( dateString.startsWith('FQ') ){
    addMethod = addQuarters
  }else if( dateString.startsWith('FY') ){
    addMethod = addYears
  }
  return subDays(addMethod(date, 1), 1)
}

export const parseCompetitiveDateLabel = (label: string): Date => {
  // NOTE - we can get all kinds of formatted strings from the API
  // which we need to turn back into dates.
  // These include e.g 'FY2425', 'FQ1-24', 'Jan-25' etc
  if( label.includes('-') ){
    const [period, yy] = label.split('-')
    const YYYY = Number(`20${yy}`)
    if( period.startsWith('FQ') ){
      const quarterIndex = Number(period[2]) - 1
      return addQuarters(new Date(YYYY, 3, 1), quarterIndex) 
    }
    return parse(period, 'MMM', new Date(YYYY, 0, 1))
  }
  const startYY = Number(label.slice(2, 4))
  return new Date(Number(`20${startYY}`), 3, 1)
}

const getDateFromCellItem = (d: CellItem): Date => (
  parseCompetitiveDateLabel(d.type_value)
)


const resolveXTickFormatter = (
  chartDataConfiguration: ChartDataConfiguration | undefined,
  granularity: CompetitiveGranularity | undefined,
  componentProps: Dictionary | undefined,
): ((tick: string | number) => string) | undefined => {
  if( chartDataConfiguration && chartDataConfiguration.deriveTimelineFormatter && granularity ){
    switch(granularity){
      case 'financial-year': return financialYearFormatter
      case 'financial-quarter': return financialQuarterFormatter
      default: return longMonthYearFormatter
    }
  }
  return componentProps && componentProps.xTickFormatter
}


export const nestedListItemsToMultiDatasets = ({
  data,
  dataFormat,
  appTheme,
  domainPositions = [],
  isTimeline = false,
  ignoreKeys = [],
}: InvestmentReportChartDataAdapterProps
): MultiDataset[] => {
  if( data.length === 1 ){
    const parentRow = data[0]
    if( parentRow.data ){
      const domain = resolveDomain({
        domain: parentRow.data.map( d => d.data_type ),
        domainPositions,
        ignoreKeys,
      })
      const colorScale = appTheme.chart.getOrdinalColourScale(domain)
      return domain.map( key => {
        const datum = parentRow.data && parentRow.data.filter( d => d.data_type === key )[0]
        const color = colorScale(key)
        const chartData: ChartData<SVGDatumType & { start?: number, end?: number }> = !datum ? [] : datum.costs.map( c => {
          const chartDatum: SVGDatumType & { start?: number, end?: number } = {
            value: Number(dataFormat === 'currency' ? c.spend : c.percent),
            label: c.type_value,
            color,
          }
          if( isTimeline ){
            const start = getDateFromCellItem(c)
            const end = inferPeriodEnd(c, start)
            chartDatum.start = start.getTime()
            chartDatum.end = end.getTime()
            chartDatum.label = start.getTime()
          }
          return chartDatum
        })
        return {
          key,
          label: key,
          color,
          data: sortBy(chartData, 'start'),
        }
      })
    }
  }

  if( data.length > 1 ){
    console.warn(
      'Too many list items to coerce to nested multi datasets',
      data,
    )
    throw new Error('Too many list items to coerce to nested multi datasets')
  }
  return []
}


export const CompetitiveHistogram = ({
  data,
  dataFormat,
  columnFilter,
  rowFilter,
  includeTotals,
  adapter,
  dataConfiguration = {},
  componentProps,
}: ChartComponentOverrideProps): JSX.Element => {
  const appTheme = useAppTheme()
  const currency = data[0] && data[0].total_currency || null
  const filteredData = filterListItems(data, {
    columnFilter,
    rowFilter,
    includeTotals,
    segmentDimensionExclusions: get(dataConfiguration, 'segmentDimensionExclusions'),
  })
  const chartData = adapter({
    data: filteredData,
    dataFormat,
    appTheme,
    ...dataConfiguration,
  }) as ChartData

  const yTickFormatter = (
    dataFormat === 'currency' ?
      (currency !== null && getMoneyFormatter(currency) || undefined) :
      percentageFormatter
  )
  
  return (
    <Box
      p={5}
      bgcolor={appTheme.palette.background.paper}>
      <ResponsiveHistogramWithTooltip
        height={CHART_HEIGHT}
        axisText
        grid
        yTickFormatter={yTickFormatter}
        numXTicks={chartData.length}
        {...componentProps}
        data={chartData} />
    </Box>
  )
}

export const CompetitiveStackedHistogram = ({
  data,
  dataFormat,
  columnFilter,
  rowFilter,
  includeTotals,
  adapter,
  dataConfiguration = {},
  componentProps,
  granularity,
}: ChartComponentOverrideProps): JSX.Element => {
  const appTheme = useAppTheme()
  const currency = data[0] && data[0].total_currency || null
  const filteredData = filterListItems(data, {
    columnFilter,
    rowFilter,
    includeTotals,
    segmentDimensionExclusions: get(dataConfiguration, 'segmentDimensionExclusions'),
  })
  const chartData = adapter({
    data: filteredData,
    dataFormat,
    appTheme,
    ...dataConfiguration,
  }) as MultiDataset[]

  const yTickFormatter = (
    dataFormat === 'currency' ?
      (currency !== null && getMoneyFormatter(currency) || undefined) :
      percentageFormatter
  )

  const xTickFormatter = resolveXTickFormatter(
    dataConfiguration, granularity, componentProps
  )
  
  return (
    <Box
      p={5}
      bgcolor={appTheme.palette.background.paper}>
      <ResponsiveStackedHistogramWithTooltip
        height={CHART_HEIGHT}
        axisText
        grid
        yTickFormatter={yTickFormatter}
        {...componentProps}
        xTickFormatter={xTickFormatter}
        datasets={chartData} />
    </Box>
  )
}


export const CompetitiveTrendMultiLine = ({
  data,
  dataFormat,
  columnFilter,
  rowFilter,
  includeTotals,
  adapter,
  dataConfiguration,
  granularity,
  componentProps,
}: ChartComponentOverrideProps): JSX.Element => {
  const appTheme = useAppTheme()
  const currency = data[0] && data[0].total_currency || null
  const filteredData = filterListItems(data, {
    columnFilter,
    rowFilter,
    includeTotals,
    segmentDimensionExclusions: get(dataConfiguration, 'segmentDimensionExclusions'),
  })
  const datasets = adapter({
    data: filteredData,
    dataFormat,
    appTheme,
    ...dataConfiguration,
  }) as MultiDataset[]

  const yTickFormatter = (
    dataFormat === 'currency' ?
      (currency !== null && getMoneyFormatter(currency) || undefined) :
      percentageFormatter
  )

  const xTickFormatter = resolveXTickFormatter(
    dataConfiguration, granularity, componentProps
  )

  return (
    <Box
      p={5}
      bgcolor={appTheme.palette.background.paper}>
      <ResponsiveMultiLine
        height={CHART_HEIGHT}
        axisText
        grid
        yTickFormatter={yTickFormatter}
        {...componentProps}
        xTickFormatter={xTickFormatter}
        datasets={datasets} />
    </Box>
  )
}
