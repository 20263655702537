import { apiClient } from 'api/clients'
import { Query, useMutation, useQuery, UseQueryResult } from 'react-query'
import { CellItem, CurrencyType, ListItem, NestedItem } from '../../components/ReportingDashboard/types'
import qs from 'query-string'
import { findIndex, flatten, isEqual, partition, range, sortBy, sum, uniq, uniqBy } from 'lodash-es'
import { DataByPeriodProps } from './MediaInvestment'
import { avg, filterTruthyMapping, getPercentageDelta } from '@percept/utils'
import { saveAs } from 'file-saver'
import { endOfMonth, format, isBefore, parse, subMonths } from 'date-fns'
import { PerformanceValue } from 'types'
import { Dictionary } from '@percept/types'

export type CompetitiveReportType = (
  'Vodafone' | 'largest-competitor' | 'nearest-competitor' | 'all-competitors' | 'gap-largest-competitor'
)
 
export type CompetitiveBrandFiltering = (
  'master-brands-only' | 'second-brands-only' | 'aggregate-second-brands'
)

export type CompetitiveGranularity = (
  'financial-year' | 'financial-quarter' | 'month'
)

type BaseCompetitiveParams = {
  report_dates?: string[]
  markets?: string[]
  currencyType?: CurrencyType
}

export type CompetitiveDataProps = BaseCompetitiveParams & {
  report_type: CompetitiveReportType
  brand_filtering?: CompetitiveBrandFiltering | null
  granularity?: CompetitiveGranularity | null
  media_channels?: string[]
  media_sub_channels?: string[]
  competitive_second_brands?: string[] | null
  competitors?: string[] | null
  actual_competitors?: string[] | null
}

export type CompetitorNearestGapDataProps = BaseCompetitiveParams


export type CompetitiveReportRow = {
  primary_id: string | null
  primary_name: string
  primary_label_name: string
  financial_year_name: string
  time_period: string
  actual_competitor_name: string | null
  currency_iso_code: string | null
  competitor_rank: number | null
  amount: string | null
  percent_amount: string | null
}

export type CompetitiveReportTotal = {
  label_name: string
  label_group_name: string
  financial_year_name: string
  time_period: string
  amount: string | null
}

export type CompetitiveReportResponse = {
  rows: CompetitiveReportRow[]
  totals: CompetitiveReportTotal[]
}


export const competitiveReportQueryKey = 'competitiveReport'

export const sosQueryKey = 'sosSpending'

export const sovQueryKey = 'sovSpending'

export const isCompetitiveReportQuery = (query: Query): boolean => (
  query.queryKey[0] === competitiveReportQueryKey
)

export const isCompetitiveSOSReportQuery = (query: Query): boolean => (
  query.queryKey[0] === competitiveReportQueryKey
  && query.queryKey[1] === sosQueryKey
)

export const isCompetitiveSOVReportQuery = (query: Query): boolean => (
  query.queryKey[0] === competitiveReportQueryKey
  && query.queryKey[1] === sovQueryKey
)


export type CompetitiveReportDataHookProps = (
  CompetitiveDataProps & {
    enabled?: boolean
    start_month?: string[]
    end_month?: string[]
  }
)

export type CompetitorNearestGapReportDataHookProps = (
  CompetitorNearestGapDataProps & {
    enabled?: boolean
  }
)

type CompetitiveListItem = ListItem & { cluster_name?: string }


type CompetitiveReportAdapterConfig = {
  requireSingleValue: boolean
  defaultCurrency?: string | null
  includeTotals?: boolean
  totalPercentageValue?: string
}


const parseRowsAndTotals = (response: CompetitiveReportResponse): [CompetitiveReportRow[], CompetitiveReportRow[]] => {
  const [rows, totals] = partition(response.rows, r => r.primary_label_name !== 'Totals')
  return [
    rows,
    totals.map( t => ({
      ...t,
      primary_id: t.primary_name,
    }))
  ]
}



const getTotalLabel = (label: string): string => (
  label === 'Total' ? 'All total' : label
)

const totalsAdapter = (
  totals: CompetitiveReportRow[],
  defaultCurrency: string | null = 'EUR',
): ListItem[] => {
  const totalsByLabel = totals.reduce( (acc, total) => {
    acc[total.primary_name] = acc[total.primary_name] || []
    acc[total.primary_name].push(total)
    return acc
  }, {} as Record<string, CompetitiveReportRow[]>)
  
  const totalListItems: ListItem[] = Object.entries(totalsByLabel).map( ([label, values]) => {
    const costs: CellItem[] = sortBy(
      values.map( val => ({
        type_value: val.time_period || val.financial_year_name,
        spend: String(val.amount),
        percent: String(val.percent_amount),
      })),
      'type_value'
    )
    return {
      row_group: getTotalLabel(label),
      costs,
      total: String(sum(costs.map( c => Number(c.spend)))),
      total_currency: defaultCurrency as string,
      total_percentage: String(avg(costs.map(c => Number(c.percent)))),
      isTotalRow: true,
    }
  })

  const allTotalIndex = findIndex(totalListItems, t => t.row_group === 'All total')
  if( allTotalIndex >= 0 ){
    const removed = totalListItems.splice(allTotalIndex, 1)
    totalListItems.push(removed[0])
  }

  return totalListItems
}


const ensureTotalOrdering = (listItems: ListItem[]): ListItem[] => {
  const allTotalIndex = findIndex(listItems, t => t.row_group === 'All total')
  if( allTotalIndex >= 0 ){
    const removed = listItems.splice(allTotalIndex, 1)
    listItems.push(removed[0])
  }
  return listItems
}

const makeCompetitiveReportByMarketAdapter = ({
  requireSingleValue,
  defaultCurrency = 'EUR',
  includeTotals = true,
  totalPercentageValue,
}: CompetitiveReportAdapterConfig) =>
  (response: CompetitiveReportResponse): CompetitiveListItem[] => {

    const [parsedRows, parsedTotals] = parseRowsAndTotals(response)
    const rows = includeTotals ? flatten([parsedRows, parsedTotals]) : parsedRows

    const rowsByMarket: Record<string, CompetitiveReportRow[]> = rows.reduce( (acc, row) => {
      const id = row.primary_id || row.primary_name
      if( id === null ){
        return acc
      }
      acc[id] = acc[id] || []
      acc[id].push(row)
      return acc
    }, {} as Record<string, CompetitiveReportRow[]>)

    const listItems: CompetitiveListItem[] = [
      ...(
        Object.entries(rowsByMarket).map( ([primary_id, rows]): CompetitiveListItem => {

          const dataByFinancialYear = rows.reduce( (acc, row) => {
            acc[row.time_period || row.financial_year_name] = acc[row.time_period || row.financial_year_name] || []
            acc[row.time_period || row.financial_year_name].push(row)
            return acc
          }, {} as Record<string, CompetitiveReportRow[]>)

          if( requireSingleValue ){
            for( const [key, val] of Object.entries(dataByFinancialYear) ){
              if( val.length !== 1 ){
                const uniqueRows = uniqBy(val, r => isEqual(r, val[0]))
                if( uniqueRows.length !== 1 ){
                  throw new Error(
                    `${primary_id} ${key} has ${val.length} entries`
                  )
                }
                dataByFinancialYear[key] = uniqueRows
                console.warn('Duplicate rows were found but handled', val)
              }
            }
          }

          const costs: CellItem[] = Object.entries(dataByFinancialYear).map( ([k, v]) => ({
            type_value: k,
            spend: String(
              requireSingleValue ?
                Number(v[0].amount) :
                sum(v.map( r => Number(r.amount)))
            ),
            percent: String(
              requireSingleValue ?
                Number(v[0].percent_amount) :
                avg(v.map( r => Number(r.amount)))
            ),
          }))

          const cluster_name = rows[0].primary_label_name
          const isTotalRow = cluster_name === 'Totals'
          const row_name = rows[0].primary_name
          const row_group = isTotalRow ? getTotalLabel(row_name) : row_name

          return {
            row_group,
            isTotalRow,
            cluster_name,
            costs: sortBy(costs, 'type_value'),
            total: String(sum(costs.map( c => Number(c.spend)))),
            total_percentage: totalPercentageValue || String(avg(costs.map( c => Number(c.percent)))),
            total_currency: defaultCurrency as string,
          }
        })
      )
    ]

    return ensureTotalOrdering(listItems.filter( i => !!i.costs.length ))
  }


type NestedCompetitiveReportAdapterConfig = {
  groupKey: keyof CompetitiveReportRow
  amountKey?: keyof CompetitiveReportRow
  percentKey?: keyof CompetitiveReportRow
  sortIteratee?: keyof NestedItem | (keyof NestedItem)[] | ((nestedItem: NestedItem) => number)
  nestedTotalPercentageValue?: string
} & Pick<CompetitiveReportAdapterConfig, 'defaultCurrency' | 'includeTotals' | 'totalPercentageValue'>

const makeNestedCompetitiveReportByMarketAdapter = ({
  groupKey,
  amountKey = 'amount',
  percentKey = 'percent_amount',
  defaultCurrency = 'EUR',
  includeTotals = true,
  sortIteratee = 'data_type',
  totalPercentageValue,
  nestedTotalPercentageValue,
}: NestedCompetitiveReportAdapterConfig) => (
  response: CompetitiveReportResponse,
): CompetitiveListItem[] => {

  const [parsedRows, parsedTotals] = parseRowsAndTotals(response)
  const rows = includeTotals ? flatten([parsedRows, parsedTotals]) : parsedRows

  const rowsByMarket: Record<string, CompetitiveReportRow[]> = rows.reduce( (acc, row) => {
    if( row.primary_id === null ){
      return acc
    }
    acc[row.primary_id] = acc[row.primary_id] || []
    acc[row.primary_id].push(row)
    return acc
  }, {} as Record<string, CompetitiveReportRow[]>)

  const listItems: CompetitiveListItem[] = [
    ...(
      Object.entries(rowsByMarket).map( ([primary_id, rows]): CompetitiveListItem => {

        const dataByFinancialYear = rows.reduce( (acc, row) => {
          acc[row.time_period || row.financial_year_name] = acc[row.time_period || row.financial_year_name] || []
          acc[row.time_period || row.financial_year_name].push(row)
          return acc
        }, {} as Record<string, CompetitiveReportRow[]>)

        const costs = Object.entries(dataByFinancialYear).map( ([k, v]) => ({
          type_value: k,
          spend: String(sum(v.map( r => Number(r.amount)))),
          percent: nestedTotalPercentageValue || String(avg(v.map( r => Number(r.percent_amount)))),
        }))

        const dataByGroupKey = rows.reduce( (acc, row) => {
          const key = String(row[groupKey])
          acc[key] = acc[key] || []
          acc[key].push(row) 
          return acc
        }, {} as Record<string, CompetitiveReportRow[]>)

        const data = sortBy(
          Object.entries(dataByGroupKey).map( ([k, v]) => {
            return {
              data_type: k,
              total: String(sum(v.map( r => Number(r[amountKey])))),
              total_percentage: String(avg(v.map( r => Number(r[percentKey])))),
              costs: sortBy(
                v.map( r => {
                  return {
                    type_value: r.time_period || r.financial_year_name,
                    spend: String(Number(r[amountKey])),
                    percent: String(Number(r[percentKey])),
                  }
                }),
                'type_value',
              ),
            }
          }),
          sortIteratee
        ) as NestedItem[]

        const cluster_name = rows[0].primary_label_name
        const isTotalRow = cluster_name === 'Totals'
        const row_name = rows[0].primary_name
        const row_group = isTotalRow ? getTotalLabel(row_name) : row_name

        return {
          row_group,
          isTotalRow,
          cluster_name,
          costs: sortBy(costs, 'type_value'),
          data,
          total: String(
            sum(costs.map( c => Number(c.spend)))
          ),
          total_percentage: totalPercentageValue || String(
            avg(costs.map( c => Number(c.percent)))
          ),
          total_currency: defaultCurrency as string,
        }
      })
    )
  ]

  return ensureTotalOrdering(listItems.filter( i => !!i.costs.length ))
}


const vodafoneSortIteratee = (nestedItem: NestedItem): number => {
  if( nestedItem.data_type.startsWith('Vodafone') ){
    return -1
  }
  return nestedItem.data_type.toLowerCase().charCodeAt(0)
}


const deriveRowIdentity = (row: CompetitiveReportRow): string => (
  [row.primary_id, row.time_period || row.financial_year_name, row.actual_competitor_name].join('|')
)

const produceRowMappingByIdentity = <T>(
  rows: T[],
  identityFn: (row: T) => string | number,
): Record<string, T> => {
  return rows.reduce( (acc, row) => {
    const key = identityFn(row)
    if( acc[key] ){
      const isDuplicateRow = isEqual(row, acc[key])
      if( isDuplicateRow ){
        console.warn(`Duplicate rows for ${key}`, acc[key], row)
        return acc
      }else{
        console.group(`Duplicate rows for ${key}`)
        console.log('Existing', acc[key])
        console.log('Added', row)
        console.groupEnd()
        throw new Error(`Row identity ${key} is not unique`)
      }
    }
    acc[key] = row
    return acc
  }, {} as Record<string, T>)
}

const ensureRowMappingsAreComparable = (
  a: Record<string, CompetitiveReportRow>,
  b: Record<string, CompetitiveReportRow>,
): true => {
  const aKey = Object.keys(a).sort().join('|')
  const bKey = Object.keys(b).sort().join('|')
  if( aKey !== bKey ){
    throw new Error('Mappings are not comparable')
  }
  return true
}

const nullableStringToPerformanceValue = (value: string | null): PerformanceValue => (
  value === null ? null : Number(value)
)

const performanceValueToNullableString = (value: PerformanceValue): string => (
  value === null ? '' : String(value)
)


const deriveCompetitorGapResponse = (
  vodafoneResponse: CompetitiveReportResponse,
  competitorResponse: CompetitiveReportResponse,
  adapterConfig: CompetitiveReportAdapterConfig,
): ListItem[] => {

  const vodafoneRows = flatten(parseRowsAndTotals(vodafoneResponse))
  const competitorRows = flatten(parseRowsAndTotals(competitorResponse))

  const vodafoneRowsByIdentity = produceRowMappingByIdentity(vodafoneRows, deriveRowIdentity)
  const competitorRowsByIdentity = produceRowMappingByIdentity(competitorRows, deriveRowIdentity)

  const allRowIdentities = uniq([
    ...Object.keys(vodafoneRowsByIdentity),
    ...Object.keys(competitorRowsByIdentity),
  ])

  const outputRows: CompetitiveReportRow[] = []

  for( const key of allRowIdentities ){
    const vodafoneRow = vodafoneRowsByIdentity[key]
    const competitorRow = competitorRowsByIdentity[key]
    let amount = '-', delta = null
    if( vodafoneRow && competitorRow ){
      amount = String(Number(vodafoneRow.amount) - Number(competitorRow.amount))
      // NOTE - the gap to competitor is currently derived as a literal delta between % share figures,
      // and is considered null if either value is null
      const vfValue = nullableStringToPerformanceValue(vodafoneRow.percent_amount)
      const competitorValue = nullableStringToPerformanceValue(competitorRow.percent_amount)
      if( vfValue !== null && competitorValue !== null ){
        delta = vfValue - competitorValue
      }
    }
    const percent_amount = performanceValueToNullableString(delta)
    outputRows.push({
      ...(vodafoneRow || competitorRow),
      amount,
      percent_amount,
    })
  }

  return makeCompetitiveReportByMarketAdapter(adapterConfig)({
    rows: outputRows,
    totals: []
  })
}


const deriveCompetitorTrendResponse = (
  vodafoneResponse: CompetitiveReportResponse,
  competitorResponse: CompetitiveReportResponse,
  adapterConfig: CompetitiveReportAdapterConfig,
): ListItem[] => {

  const vodafoneRows = flatten(parseRowsAndTotals(vodafoneResponse))
  const competitorRows = flatten(parseRowsAndTotals(competitorResponse))

  const vodafoneRowsByIdentity = produceRowMappingByIdentity(vodafoneRows, deriveRowIdentity)
  const competitorRowsByIdentity = produceRowMappingByIdentity(competitorRows, deriveRowIdentity)

  const allRowIdentities = uniq([
    ...Object.keys(vodafoneRowsByIdentity),
    ...Object.keys(competitorRowsByIdentity),
  ])

  const outputRows: CompetitiveReportRow[] = []

  for( const key of allRowIdentities ){
    const vodafoneRow = vodafoneRowsByIdentity[key]
    const competitorRow = competitorRowsByIdentity[key]
    let amount = '-', delta = null
    if( vodafoneRow && competitorRow ){
      amount = String(Number(vodafoneRow.amount) - Number(competitorRow.amount))
      delta = getPercentageDelta(
        nullableStringToPerformanceValue(vodafoneRow.amount),
        nullableStringToPerformanceValue(competitorRow.amount),
      )
    }
    const seedRow = vodafoneRow || competitorRow
    const percent_amount = performanceValueToNullableString(delta)
    outputRows.push(
      {
        ...(vodafoneRow || seedRow),
        actual_competitor_name: 'Vodafone',
      },
      {
        ...(competitorRow || seedRow),
        actual_competitor_name: 'Largest Competitor',
      },
      {
        ...seedRow,
        actual_competitor_name: 'Difference',
        amount,
        percent_amount,
      },
    )
  }

  return makeNestedCompetitiveReportByMarketAdapter({ ...adapterConfig, groupKey: 'actual_competitor_name' })({
    rows: outputRows,
    totals: []
  })
}


const competitiveReportByClusterAdapter = (response: CompetitiveReportResponse): ListItem[] => {
  const { rows, totals } = response

  const rowsByTotal: Record<string, CompetitiveReportRow[]> = totals.reduce( (acc, total) => {
    if( total.label_name !== 'Total' ){
      acc[total.label_name] = []
    }
    return acc
  }, {
    'All Other': []
  } as Record<string, CompetitiveReportRow[]>)

  rows.forEach( row => {
    if( rowsByTotal[row.primary_label_name] ){
      rowsByTotal[row.primary_label_name].push(row)
    }else{
      rowsByTotal['All Other'].push(row)
    }
  })

  const listItems: ListItem[] = [
    ...(
      totals.map( (total): ListItem => {

        const rows = rowsByTotal[total.label_name] || []

        const dataByFinancialYear = rows.reduce( (acc, row) => {
          acc[row.time_period || row.financial_year_name] = acc[row.time_period || row.financial_year_name] || []
          acc[row.time_period || row.financial_year_name].push(row)
          return acc
        }, {} as Record<string, CompetitiveReportRow[]>)

        const costs = Object.entries(dataByFinancialYear).map( ([k, v]) => ({
          type_value: k,
          spend: String(sum(v.map( r => r.amount))),
          percent: '100',
        }))

        const dataByMarket = rows.reduce( (acc, row) => {
          acc[row.primary_name] = acc[row.primary_name] || []
          acc[row.primary_name].push(row) 
          return acc
        }, {} as Record<string, CompetitiveReportRow[]>)

        const data: NestedItem[] = sortBy(
          Object.entries(dataByMarket).map( ([k, v]) => {
            return {
              data_type: k,
              total: String(sum(rows.map( r => r.amount))),
              total_percentage: '100',
              costs: sortBy(
                v.map( r => {
                  return {
                    type_value: r.time_period || r.financial_year_name,
                    spend: String(r.amount),
                    percent: String(r.percent_amount),
                  }
                }),
                'type_value',
              ),
            }
          }),
          'data_type'
        )

        return {
          row_group: getTotalLabel(total.label_name),
          costs,
          data,
          total: String(total.amount),
          total_percentage: '100',
          total_currency: rows[0] && rows[0].currency_iso_code || 'EUR',
        }
      })
    )
  ]
  return listItems.filter( i => !!i.costs.length )
}


const getMonthIndexForFinancialYear = (date: Date): number => {
  let monthIndex = date.getMonth()
  if( monthIndex < 3 ){
    return monthIndex + 9
  }
  return monthIndex -= 3
}


const resolveCompetitiveReportDates = ({
  startMonth,
  startYear,
  endMonth,
  endYear,
}: {
  startMonth: string | undefined,
  startYear?: number,
  endMonth: string | undefined,
  endYear?: number,
}): string[] | undefined => {
  if(
    typeof startMonth === 'undefined'
    || typeof endMonth === 'undefined'
    || startMonth === 'Jan' && endMonth === 'Dec'
  ){
    return
  }

  const referenceDate = new Date()
  const minMonth = parse(startMonth, 'MMM', referenceDate)
  const maxMonth = parse(endMonth, 'MMM', referenceDate)

  // NOTE - we need to parse these in the context of Financial Year
  const fyStartMonthIndex = getMonthIndexForFinancialYear(minMonth)
  const fyEndMonthIndex = getMonthIndexForFinancialYear(maxMonth)
  const fyMonthIndices = range(fyStartMonthIndex, fyEndMonthIndex + 1)

  if( fyMonthIndices.length === 12 ){
    // User has selected contiguous month range for the entire year,
    // functionally equivalent to no filter applied.
    return
  }

  // Ensure the latest date we request is the last completed month
  const maxDate = endOfMonth(subMonths(new Date(), 1))

  const reportDates = flatten(
    range(startYear || 2015, (endYear || new Date().getFullYear()) + 1).reduce( (acc, yyyy) => {
      fyMonthIndices.forEach( monthIndex => {
        const normalisedMonthIndex = monthIndex < 9 ? monthIndex + 3 : monthIndex - 9
        const resolvedYYYY = monthIndex >= 9 ? yyyy + 1 : yyyy
        const normalisedDate = new Date(resolvedYYYY, normalisedMonthIndex, 1)
        if( isBefore(normalisedDate, maxDate) ){
          acc.push(format(normalisedDate, 'yyyy-MM-dd'))
        }
      })
      return acc
    }, [] as string[])
  )

  return reportDates
}


const resolveCompetitiveReportEndpointParams = ({
  competitiveType,
  report_type,
  brand_filtering,
  granularity,
  currencyType = 'dynamic',
  start_month = [],
  end_month = [],
  media_channels = [],
  competitors = null,
  actual_competitors = null,
  competitive_second_brands = null,
  startYear,
}: CompetitiveReportDataHookProps & {
    competitiveType: 'SOS' | 'SOV'
    startYear?: number
  }
): Dictionary => {
  const currencyEnabled = competitiveType === 'SOS'

  const report_dates = resolveCompetitiveReportDates({
    startMonth: start_month[0],
    endMonth: end_month[0],
    startYear,
  }) || []

  // NOTE - only 'All Competitors' currently supports second brand aggregation and brand filter behaviour
  let aggregate_second_brands = false
  let brand_filtering_param: CompetitiveBrandFiltering | null | undefined = null
  if( report_type === 'all-competitors' ){
    aggregate_second_brands = brand_filtering === 'aggregate-second-brands'
    brand_filtering_param = brand_filtering === 'aggregate-second-brands' ? null : brand_filtering
  }

  const params: Record<string, string | string[] | boolean | null> = {
    report_type,
    report_dates,
    aggregate_second_brands,
    ...filterTruthyMapping({
      brand_filtering: brand_filtering_param,
      granularity,
      competitors,
      actual_competitors,
      competitive_second_brands,
    }),
  }

  if( currencyEnabled ){
    params.currency_type = currencyType
  }

  if( competitiveType === 'SOS' ){
    params.media_channels = media_channels
  }

  return params
}


type CompetitiveReportQueryOptions = Partial<Record<keyof BaseCompetitiveParams, boolean>>

const makeCompetitiveReportQuery = (
  queryKey: string,
  route: string,
  options: CompetitiveReportQueryOptions = {
    currencyType: true,
  },
): ((props: CompetitiveReportDataHookProps & { startYear?: number }) => UseQueryResult<CompetitiveReportResponse, Error>) => {
  return ({
    enabled = true,
    report_type,
    brand_filtering,
    granularity,
    // report_dates = [],
    start_month = [],
    end_month = [],
    media_channels = [],
    currencyType = 'dynamic',
    startYear,
    // markets = [],
    // media_sub_channels = [],
    competitors = null,
    competitive_second_brands = null,
    actual_competitors = null,
  }) => {
    const currencyEnabled = options.currencyType !== false

    // NOTE - we need to detect SOV here, as we need to selectively apply different
    // filters and also amend the query key as necessary to avoid re-requesting the same data.
    const isSOV = route.includes('sov')

    const params = resolveCompetitiveReportEndpointParams({
      competitiveType: isSOV ? 'SOV' : 'SOS',
      brand_filtering,
      granularity,
      report_type,
      start_month,
      end_month,
      media_channels,
      currencyType,
      startYear,
      competitors,
      competitive_second_brands,
      actual_competitors,
    })

    const queryKeyArray: (string | string[] | boolean)[] = [
      competitiveReportQueryKey,
      queryKey,
      report_type,
      params.granularity,
      competitors,
      competitive_second_brands,
      actual_competitors,
      params.report_dates,
      params.brand_filtering,
      params.aggregate_second_brands,
    ]

    if( currencyEnabled ){
      queryKeyArray.push(currencyType)
    }

    if( !isSOV ){
      queryKeyArray.push(media_channels)
    }

    return useQuery<CompetitiveReportResponse, Error>(
      queryKeyArray,
      async () => {
        const res = await apiClient.get<CompetitiveReportResponse>(
          route,
          { params, paramsSerializer: qs.stringify },
        )
        return res.data
      },
      {
        enabled,
        staleTime: 1000 * 60 * 5,
        retry: false,
      }
    )
  }
}


const makeCompetitiveReportAdapterHook = (
  useCompetitiveResponse: (props: CompetitiveReportDataHookProps) => UseQueryResult<CompetitiveReportResponse, Error>,
  adapter: (response: CompetitiveReportResponse) => ListItem[],
  report_type: CompetitiveReportType,
): ((props: DataByPeriodProps) => UseQueryResult<ListItem[], Error>) => {
  return props => {
    const hookValue = useCompetitiveResponse({
      ...props,
      report_type,
    })
    return {
      ...hookValue,
      data: hookValue.data === undefined ? undefined : adapter(hookValue.data)
    } as UseQueryResult<ListItem[], Error>
  }
}


const makeDerivedCompetitorTrendQueryHook = (
  useCompetitiveSpendingHook: (props: CompetitiveReportDataHookProps) => UseQueryResult<CompetitiveReportResponse, Error>,
  competitorReportType: CompetitiveReportType,
  reducer: (vodafone: CompetitiveReportResponse, competitor: CompetitiveReportResponse) => ListItem[],
) => {
  if( competitorReportType === 'Vodafone' ){
    throw new Error('Vodafone report type cannot be used as competitor report type in derived trend query hook')
  }
  return (props: DataByPeriodProps): UseQueryResult<ListItem[], Error> => {
    const vodafoneResponse = useCompetitiveSpendingHook({
      report_type: 'Vodafone',
      ...props,
    })
    const competitorResponse = useCompetitiveSpendingHook({
      report_type: competitorReportType,
      ...props
    })
    const isLoading = vodafoneResponse.isLoading || competitorResponse.isLoading
    const isError = vodafoneResponse.isError || competitorResponse.isError
    return {
      ...vodafoneResponse,
      isLoading,
      isError,
      data: (
        (vodafoneResponse.data && competitorResponse.data) ?
          reducer(vodafoneResponse.data, competitorResponse.data) :
          undefined
      ), 
    } as UseQueryResult<ListItem[], Error>
  }
}


const useSOSSpending = makeCompetitiveReportQuery(
  sosQueryKey,
  '/competitive-reports/sos-spending',
)

const useSOVSpending = makeCompetitiveReportQuery(
  sovQueryKey,
  '/competitive-reports/sov-spending',
  { currencyType: false },
)


export default {
  // Export
  useCompetitiveReportExport: ({ competitiveType }: { competitiveType: 'SOS' | 'SOV' }) => {
    return useMutation({
      mutationFn: async (props: CompetitiveReportDataHookProps) => {
        const params = resolveCompetitiveReportEndpointParams({
          competitiveType,
          ...props
        })

        const route = `/competitive-reports/${competitiveType.toLowerCase()}-spending/export`

        const response = await apiClient.get<Blob>(
          route,
          {
            params,
            paramsSerializer: qs.stringify,
            responseType: 'blob',
            headers: {
              'Content-Type': 'text/csv',
            }
          }
        )

        // Get filename from content disposition header if available
        const contentDisposition: string = response.headers && response.headers['content-disposition']
        let filename = `Competitive${competitiveType}Export.csv`
        if( contentDisposition ){
          const headerValues = contentDisposition.split(';').map( s => s.trim())
          const filenameValue = headerValues.find( v => v.startsWith('filename='))
          if( filenameValue ){
            filename = filenameValue.replace('filename=', '')
          }
        }

        saveAs(response.data, filename)
      }
    })
  },
  // SOS
  useSOSSpendingByMarket: makeCompetitiveReportAdapterHook(
    useSOSSpending,
    makeCompetitiveReportByMarketAdapter({
      requireSingleValue: true,
    }),
    'Vodafone',
  ),
  useSOSSpendingByCluster: makeCompetitiveReportAdapterHook(
    useSOSSpending,
    competitiveReportByClusterAdapter,
    'Vodafone',
  ),
  useSOSSpendingAllCompetitorsByMarket: makeCompetitiveReportAdapterHook(
    useSOSSpending,
    makeNestedCompetitiveReportByMarketAdapter({
      groupKey: 'actual_competitor_name',
      amountKey: 'amount',
      percentKey: 'percent_amount',
      includeTotals: false,
      sortIteratee: vodafoneSortIteratee,
      totalPercentageValue: '100',
      nestedTotalPercentageValue: '100',
    }),
    'all-competitors',
  ),
  useSOSSpendingLargestCompetitorByMarket: makeCompetitiveReportAdapterHook(
    useSOSSpending,
    makeCompetitiveReportByMarketAdapter({
      requireSingleValue: true,
    }),
    'largest-competitor',
  ),
  useSOSLargestCompetitorTrend: makeDerivedCompetitorTrendQueryHook(
    useSOSSpending,
    'largest-competitor',
    (a, b) => deriveCompetitorTrendResponse(a, b, {
      requireSingleValue: true,
    }),
  ),
  useSOSLargestCompetitorGap: makeCompetitiveReportAdapterHook(
    useSOSSpending,
    makeCompetitiveReportByMarketAdapter({
      requireSingleValue: true,
    }),
    'gap-largest-competitor',
  ),
  // useSOSLargestCompetitorGap: makeDerivedCompetitorTrendQueryHook(
  //   useSOSSpending,
  //   'largest-competitor',
  //   (a, b) => deriveCompetitorGapResponse(a, b, {
  //     requireSingleValue: true,
  //   })
  // ),
  useSOSNearestCompetitorGap: makeCompetitiveReportAdapterHook(
    useSOSSpending,
    makeCompetitiveReportByMarketAdapter({
      requireSingleValue: true,
    }),
    'nearest-competitor',
  ),
  // SOV
  useSOVSpendingByMarket: makeCompetitiveReportAdapterHook(
    useSOVSpending,
    makeCompetitiveReportByMarketAdapter({
      requireSingleValue: true,
      defaultCurrency: null,
    }),
    'Vodafone'
  ),
  useSOVSpendingAllCompetitorsByMarket: makeCompetitiveReportAdapterHook(
    useSOVSpending,
    makeNestedCompetitiveReportByMarketAdapter({
      groupKey: 'actual_competitor_name',
      amountKey: 'amount',
      percentKey: 'percent_amount',
      defaultCurrency: null,
      includeTotals: false,
      sortIteratee: vodafoneSortIteratee,
      totalPercentageValue: '100',
      nestedTotalPercentageValue: '100',
    }),
    'all-competitors',
  ),
  useSOVSpendingLargestCompetitorByMarket: makeCompetitiveReportAdapterHook(
    useSOVSpending,
    makeCompetitiveReportByMarketAdapter({
      requireSingleValue: true,
      defaultCurrency: null,
    }),
    'largest-competitor',
  ),
  useSOVLargestCompetitorTrend: makeDerivedCompetitorTrendQueryHook(
    useSOVSpending,
    'largest-competitor',
    (a, b) => deriveCompetitorTrendResponse(a, b, {
      requireSingleValue: true,
      defaultCurrency: null,
    })
  ),
  useSOVLargestCompetitorGap: makeDerivedCompetitorTrendQueryHook(
    useSOVSpending,
    'largest-competitor',
    (a, b) => deriveCompetitorGapResponse(a, b, {
      requireSingleValue: true,
      defaultCurrency: null,
    }),
  ),
  useSOVNearestCompetitorGap: makeCompetitiveReportAdapterHook(
    useSOVSpending,
    makeCompetitiveReportByMarketAdapter({
      requireSingleValue: true,
      defaultCurrency: null,
    }),
    'nearest-competitor',
  ),
}
