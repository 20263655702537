import React from 'react'

import { DatePicker, DatePickerProps } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import { Button, Divider, InputAdornment, InputProps as InputPropsType, Toolbar, Typography } from '@material-ui/core'

import { makeAppStyles } from '../../themes'

import { Today } from '../../icons'

import { isNumber } from 'lodash-es'

import { format, isAfter, isBefore } from 'date-fns'

import { DateType } from '@percept/types'


const useDateRangeStyles = makeAppStyles( theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  picker: {
    marginRight: theme.spacing(2),
  },
  icon: {
    fontSize: '1.2em',
  },
}))


export type DateRangeValue = [DateType, DateType]


export type DateRangeInputProps = (
  Omit<
    DatePickerProps, 'value' | 'onChange'
  > & {
    value: DateRangeValue
    domain?: [DateType | null, DateType | null]
    onChange: (dates: [number, number]) => void
    divided?: boolean
  }
)

const dateToNumber = (d: DateType): number => (
  isNumber(d) ?
    d :
    new Date(d).getTime()
)

const useToolbarStyles = makeAppStyles( theme => ({
  root: ({ color }: { color: 'primary' | 'secondary' }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    height: '100px',
    justifyContent: 'center',
    backgroundColor: theme.palette[color].main,
    color: theme.palette[color].contrastText,
  }),
  button: {
    padding: 0,
    minWidth: 16,
    textTransform: 'none',
  },
  text: ({ color }: { color: 'primary' | 'secondary' }) => ({
    color: theme.palette[color].contrastText,
  }),
  selectedText: ({ color }: { color: 'primary' | 'secondary' }) => ({
    color: theme.palette[color].contrastText,
  }),
}))


type DatePickerView = 'date' | 'year' | 'month' | 'hours' | 'minutes' | 'seconds'

type DatePickerToolbarProps = {
  date: MaterialUiPickersDate
  openView: DatePickerView
  setOpenView: (view: DatePickerView) => void
}

/* eslint-disable react/prop-types */
const ThemedToolbarComponent = ({
  classes,
  date,
  openView,
  setOpenView,
}: (
  DatePickerToolbarProps & {
    classes: Record<'root' | 'button' | 'selectedText' | 'text', string>
  }
)): JSX.Element => {
  const dateObject = new Date(date && date.toISOString() || new Date())
  const yearTypographyClassName = openView === 'year' ? classes.selectedText : classes.text
  const dateTypographyClassName = openView === 'date' ? classes.selectedText : classes.text
  return (
    <Toolbar
      classes={{
        root: classes.root,
      }}>
      <Button className={classes.button} onClick={(): void => setOpenView('year')}>
        <Typography className={yearTypographyClassName} variant='subtitle1'>
          { format(dateObject, 'yyyy') }
        </Typography>
      </Button>
      <Button className={classes.button} onClick={(): void => setOpenView('date')}>
        <Typography className={dateTypographyClassName} variant='h4'>
          { format(dateObject, 'EEE, MMM dd')}
        </Typography>
      </Button>
    </Toolbar>
  )
}

export const DateRangeInput = ({
  value,
  domain,
  onChange,
  InputProps = {},
  label,
  color = 'primary',
  divided = false,
  ...props
}: DateRangeInputProps): JSX.Element => {

  const [start, end] = value.map(dateToNumber)

  const [min, max] = domain || [null, null]

  const classes = useDateRangeStyles()

  const toolbarClasses = useToolbarStyles({ color })

  const ToolbarComponent = (props: DatePickerToolbarProps): JSX.Element => (
    <ThemedToolbarComponent
      classes={toolbarClasses}
      date={props.date}
      openView={props.openView}
      setOpenView={props.setOpenView} />
  )

  const inputProps: Partial<InputPropsType> = {
    startAdornment: (
      <InputAdornment position='start'>
        <Today className={classes.icon} />
      </InputAdornment>
    ),
    ...InputProps,
  }

  return (
    <div className={classes.root}>

      <DatePicker
        variant='inline'
        format='dd/MM/yy'
        className={classes.picker}
        minDate={min || undefined}
        maxDate={max || undefined}
        ToolbarComponent={ToolbarComponent}
        color={color}
        {...props}
        label={label}
        InputProps={inputProps}
        value={start}
        onChange={(newStart): void => {
          if( newStart ){
            const endDate = new Date(end)
            const newEnd = isAfter(newStart, endDate) ? newStart : endDate
            onChange([newStart.getTime(), newEnd.getTime()])
          }
        }} />

      { divided && <Divider flexItem orientation='vertical' /> }

      <DatePicker
        variant='inline'
        format='dd/MM/yy'
        minDate={min || undefined}
        maxDate={max || undefined}
        ToolbarComponent={ToolbarComponent}
        color={color}
        {...props}
        // trigger an empty label holder for sizing consistency
        // if a valid label is supplied for the overall input
        label={label ? ' ' : undefined}
        InputProps={inputProps}
        value={end}
        onChange={(newEnd): void => {
          if( newEnd ){
            const startDate = new Date(start)
            const newStart = isBefore(newEnd, startDate) ? newEnd : startDate
            onChange([newStart.getTime(), newEnd.getTime()])
          }
        }} />

    </div>
  )
}