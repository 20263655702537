import React from 'react'

import {
  Box,
  CheckboxGroup,
  ClassNameMap,
  FilterPanelRenderProps,
  FormLabel,
  MenuOption,
  Radio,
  RadioFormGroup,
  RangeSlider,
  RangeSliderValue,
  makeAppStyles,
} from '@percept/mui'

import { get } from 'lodash-es'

import { FilterItem, FilterPanelState } from './typings'
import { CompetitiveBrandFiltering, CompetitiveGranularity } from 'api/services/CompetitiveReports'


export type FilterPanelClassName = 'card' | 'formGroup' | 'nestedFilterGroup' | 'optionExplainer'

export type FilterItemComponentProps = FilterPanelRenderProps<FilterPanelState> & {
  classes: ClassNameMap<FilterPanelClassName>
  filterItem: FilterItem
  disabled?: boolean
}

export const DefaultFilterItemComponent = ({
  filterItem,
  values,
  updateValues,
  classes,
  disabled,
}: FilterItemComponentProps): JSX.Element => {
  if( filterItem.groups.length > 1 ){
    return (
      <div>
        <FormLabel>
          <strong>{ filterItem.filter_name }</strong>
        </FormLabel>
        <Box display='flex' flexWrap='nowrap'>
          { filterItem.groups.map( group => (
            <div className={classes.nestedFilterGroup} key={group.group_value}>
              <CheckboxGroup
                disabled={disabled}
                name={
                  <span>
                    <Radio
                      size='small'
                      checked={!!get(values[group.group_value], 'length')}
                      disabled
                      style={{paddingLeft: 0}} />
                    {group.group_name}
                  </span>
                }
                scrollable
                maxHeight='30rem'
                value={disabled ? undefined : values[group.group_value]}
                options={
                  group.options.map( o => ({
                    label: o.name,
                    value: o.value,
                  }))
                }
                onChange={(value): void => {
                  const existingSiblingUpdate = filterItem.groups.reduce( (acc, g) => {
                    if( g.group_value !== group.group_value ){
                      acc[g.group_value] = []
                      return acc
                    }
                    return acc
                  }, {} as Partial<FilterPanelState>)
                  updateValues({
                    ...existingSiblingUpdate,
                    [group.group_value]: value
                  })
                }} />
            </div>
          ))}
        </Box>
      </div>
    )
  }
  const group = filterItem.groups[0]
  return (
    <CheckboxGroup
      name={<strong>{group.group_name}</strong>}
      FormGroupProps={{className: classes.formGroup}}
      scrollable
      maxHeight='30rem'
      disabled={disabled}
      value={disabled ? undefined : values[group.group_value]}
      options={
        group.options.map( o => ({
          label: o.name,
          value: o.value,
        }))
      }
      onChange={(value): void => {
        updateValues({ [group.group_value]: value })
      }} />
  )
}


const monthMarks: {value: number, label: string, valueString: string}[] = [
  {value: 11, label: 'April', valueString: 'Apr'},
  {value: 10, label: 'May', valueString: 'May'},
  {value: 9, label: 'June', valueString: 'Jun'},
  {value: 8, label: 'July', valueString: 'Jul'},
  {value: 7, label: 'August', valueString: 'Aug'},
  {value: 6, label: 'September', valueString: 'Sep'},
  {value: 5, label: 'October', valueString: 'Oct'},
  {value: 4, label: 'November', valueString: 'Nov'},
  {value: 3, label: 'December', valueString: 'Dec'},
  {value: 2, label: 'January', valueString: 'Jan'},
  {value: 1, label: 'February', valueString: 'Feb'},
  {value: 0, label: 'March', valueString: 'Mar'},
]

export const getMonthDisplayLabel = (valueString: string): string => {
  const mark = monthMarks.find( m => m.valueString === valueString )
  return mark ? mark.label : valueString
}

const monthValueLabelMap = monthMarks.reduce( (acc, mark) => {
  acc[mark.value] = mark.label
  return acc
}, {} as Record<number, string>)

const monthValueStringMap = monthMarks.reduce( (acc, mark) => {
  acc[mark.value] = mark.valueString
  return acc
}, {} as Record<number, string>)

const monthStringValueMap = monthMarks.reduce( (acc, mark) => {
  acc[mark.valueString] = mark.value
  return acc
}, {} as Record<string, number>)

const getMonthRangeValue = (values: FilterPanelState): RangeSliderValue => {
  const value: RangeSliderValue = [11, 0]
  if( values.months ){
    const [start, end] = values.months.split('-')
    value[0] = monthStringValueMap[start]
    value[1] = monthStringValueMap[end]
  }
  return value
}

const useMonthRangeStyles = makeAppStyles({
  markLabel: {
    fontSize: 14,
  },
})

export const MonthRangeFilterItemComponent = ({
  filterItem,
  values,
  updateValues,
  disabled,
}: FilterItemComponentProps): JSX.Element => {
  const value = getMonthRangeValue(values)
  const classes = useMonthRangeStyles()
  return (
    <div style={{minWidth: 120}}>
      <FormLabel disabled={disabled}>
        <strong>Months</strong>
      </FormLabel>
      <div style={{height: 320, marginTop: 24}}>
        <RangeSlider
          classes={classes}
          disabled={disabled}
          value={disabled ? [11, 0] : value}
          marks={monthMarks}
          step={1}
          orientation='vertical'
          domain={[0, 11]}
          valueLabelFormat={(value) => <span style={{fontSize: 14}}>{monthValueLabelMap[value]}</span>}
          onChange={(e, rangeValue): void => {
            updateValues({
              months: (
                `${monthValueStringMap[rangeValue[1]]}-${monthValueStringMap[rangeValue[0]]}`
              ),
            } as Partial<FilterPanelState>)
          }} />
      </div>
    </div>
  )
}


export const CompetitiveBrandFilterItemComponent = ({
  filterItem,
  values,
  updateValues,
}: FilterItemComponentProps): JSX.Element => {
  return (
    <RadioFormGroup
      name={<strong>Brand Filtering</strong>}
      value={values.brand_filtering || null}
      options={
        [
          {
            value: null,
            label: 'Separate Master Brands and Second Brands',
          },
          {
            value: 'aggregate-second-brands',
            label: 'Aggregate Second Brands',
          },
          {
            value: 'master-brands-only',
            label: 'Master Brands Only',
          },
          {
            value: 'second-brands-only',
            label: 'Second Brands Only',
          }
        ] as MenuOption<CompetitiveBrandFiltering>[]
      }
      onChange={(value): void => {
        const update = {
          brand_filtering: value || undefined
        } as Partial<FilterPanelState>
        if( value === 'master-brands-only' ){
          update['competitive-second-brand'] = undefined
        }
        if( value === 'second-brands-only' ){
          update.competitor = undefined
        }
        updateValues(update)
      }} />
  )
}


export const CompetitiveGranularityFilterItemComponent = ({
  filterItem,
  values,
  updateValues,
}: FilterItemComponentProps): JSX.Element => {
  return (
    <RadioFormGroup
      name={<strong>Granularity</strong>}
      value={values.granularity || 'financial-year'}
      options={
        [
          {
            value: 'financial-year',
            label: 'Financial Year',
          },
          {
            value: 'financial-quarter',
            label: 'Financial Quarter',
          },
          {
            value: 'month',
            label: 'Month',
          },
        ] as MenuOption<CompetitiveGranularity>[]
      }
      onChange={(granularity): void => {
        const update = { granularity } as Partial<FilterPanelState>
        if( granularity !== 'financial-year' ){
          update.months = undefined
        }
        updateValues(update)
      }} />
  )
}
