import React, { useEffect } from 'react'

import { useHistory } from 'react-router'

import { apiClients } from '@percept/hooks'

import { locationToUserFavourite } from '../UserFavourites/lib'

import { get } from 'lodash-es'

import { PrimaryApplication, SecondaryApplication, UserEventType } from '../UserEvents'


const DEBUG = process.env.NODE_ENV === 'development'


type LocationLike = {
  pathname: string
  search: string
}

type UserEventPayload = {
    event_type: UserEventType
    url: string
    primary_application: PrimaryApplication
    secondary_application: SecondaryApplication | null
    org_unit_id: string | null
    report_series_id: string | null
}

const initialLocation = window.location

const getTrackingUrl = (location: LocationLike): string => (
  `${initialLocation.protocol}//${initialLocation.host}${location.pathname}${location.search}`
)

export const trackPageView = (location: LocationLike): void => {
  const url = getTrackingUrl(location)
  const viewModel = locationToUserFavourite(location)
  if( !viewModel ){
    if( DEBUG ){
      console.warn(
        'Unhandled route classification', location.pathname
      )
    }
    return
  }
  const payload: UserEventPayload = {
    url,
    event_type: 'PAGE_VIEW',
    primary_application: viewModel.primary_application,
    secondary_application: viewModel.secondary_application,
    org_unit_id: get(viewModel, ['view_parameters', 'org_unit_id']) || null,
    report_series_id: get(viewModel, ['view_parameters', 'series_id']) || null,
  }
  apiClients.core.post('/user-events/', payload).then( response => {
    if( DEBUG ){
      console.log('Page view successfully recorded', response.data)
    }
  }).catch( e => {
    if( DEBUG ){
      console.error('Page view could not be recorded', e)
    }
  })
}


let hasTrackedLandingPage = false


const LivePageViewTracker = (): null => {
  const { listen } = useHistory()

  useEffect(() => {
    if( !hasTrackedLandingPage ){
      hasTrackedLandingPage = true
      trackPageView(initialLocation)
    }
    const unlisten = listen((location, action) => {
      if( DEBUG ){
        console.log('History action', action, location)
        location.state
      }
      if( action === 'PUSH' || get(location.state, 'track') === true ){
        trackPageView(location)
      }
    })

    return unlisten
  }, [listen])
  return null
}


export const PageViewTracker = (): JSX.Element | null => {
  if( DEBUG ){
    console.log('Page view tracking disabled in development')
    return null
  }
  return <LivePageViewTracker />
}
