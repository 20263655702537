import React from 'react'

import { useLocation } from 'react-router'

import {
  Box,
  Card,
  FilterPanel,
  FilterPanelProps,
  Grid,
  Loader,
  makeAppStyles,
  MenuOption,
  RoundedPlainTextButtonMenu,
  Typography,
} from '@percept/mui'

import { stringifyUrlSearchParams, useUrlState } from '@percept/hooks'

import { last, uniq } from 'lodash-es'

import { useInvestmentFilters } from './useInvestmentFilters'
import { useCompetitiveFilters, useCompetitiveSOSFilters, useCompetitiveSOVFilters } from './useCompetitiveFilters'

import { FilterItem, FilterPanelState } from './typings'
import {
  CompetitiveBrandFilterItemComponent,
  CompetitiveGranularityFilterItemComponent,
  DefaultFilterItemComponent,
  FilterItemComponentProps,
  FilterPanelClassName,
  getMonthDisplayLabel,
  MonthRangeFilterItemComponent,
} from './filterPanelComponents'
import { DashboardType } from 'redux/ReportingDashboard/reducers/filtersReducer'
import { Reporting } from 'ReportingRoutes'
import { ArrowDropDown, EuroSymbol } from '@material-ui/icons'
import { CurrencyType } from '../types'
import { useSavedQueryStrings } from 'hooks'
import { CompetitiveBrandFiltering, CompetitiveGranularity } from 'api/services/CompetitiveReports'


export type MediaInvestmentFilterPanelProps = {
  dashboardType: DashboardType
  allowedFilters: FilterItem[]
}


const filterHooksByDashboardType: Record<
  DashboardType, (filterItems: FilterItem[]) => { result: FilterItem[], loading: boolean }
> = {
  filtersMedia: useInvestmentFilters,
  filtersCompetitive: useCompetitiveFilters,
  filtersCompetitiveSOS: useCompetitiveSOSFilters,
  filtersCompetitiveSOV: useCompetitiveSOVFilters,
}

const filterItemsToFilterPanelState = (allowedFilters: FilterItem[]): FilterPanelState => {
  const defaultFilterPanelState = allowedFilters.reduce( (acc, filterItem) => {
    filterItem.groups.forEach( group => {
      // We want to maintain the default typing whilst actually sending these as undefined, so
      // we don't end up with a giant URL full of empty params
      acc[group.group_value] = undefined as unknown as string[]
    })
    return acc
  }, {} as Partial<FilterPanelState>)
  if( defaultFilterPanelState['start-month'] && defaultFilterPanelState['end-month'] ){
    defaultFilterPanelState['months'] = (
      `${defaultFilterPanelState['start-month'][0]}-${defaultFilterPanelState['end-month'][0]}`
    )
    delete defaultFilterPanelState['start-month']
    delete defaultFilterPanelState['end-month']
  }
  return {
    months: undefined,
    currency: 'dynamic',
    brand_filtering: undefined,
    granularity: undefined,
    ...defaultFilterPanelState,
  } as FilterPanelState
}

// const storedFiltersToFilterPanelState = (
//   storedFilters: StoredFilterState,
//   allowedFilters: FilterItem[]
// ): StoredFilterState => {
//   // const existingFilterPanelState = storedFilters.reduce( (acc, filter) => {
//   //   acc[filter.options.group_value] = filter.options.options.map( o => o.value )
//   //   return acc
//   // }, {} as FilterPanelState)
//   const defaultFilterPanelState = allowedFilters.reduce( (acc, filterItem) => {
//     filterItem.groups.forEach( group => {
//       // We want to maintain the default typing whilst actually sending these as undefined, so
//       // we don't end up with a giant URL full of empty params
//       acc[group.group_value] = undefined as unknown as string[]
//     })
//     return acc
//   }, {} as FilterPanelState)
//   const mergedFilters = {
//     months: undefined,
//     ...defaultFilterPanelState,
//     ...storedFilters,
//   } as StoredFilterState
//   if( mergedFilters['start-month'] && mergedFilters['end-month'] ){
//     mergedFilters['months'] = (
//       `${mergedFilters['start-month'][0]}-${mergedFilters['end-month'][0]}`
//     )
//     delete mergedFilters['start-month']
//     delete mergedFilters['end-month']
//   }
//   return mergedFilters
// }


const useStyles = makeAppStyles<{}, FilterPanelClassName>( theme => ({
  card: {
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  formGroup: {
    backgroundColor: theme.palette.background.paper,
  },
  nestedFilterGroup: {
    marginRight: theme.spacing(2),
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  optionExplainer: {
    fontSize: 12,
  },
}))

const filterItemComponentOverrides: Record<string, ((props: FilterItemComponentProps) => JSX.Element) | null> = {
  'start-month': MonthRangeFilterItemComponent,
  'end-month': null,  // Disable end month as we control the range from 'start-month'
  'brand-filtering': CompetitiveBrandFilterItemComponent,
  'granularity': CompetitiveGranularityFilterItemComponent,
}

const isFilterItemDisabled = (
  filterItem: FilterItem, values: FilterPanelState
): boolean => {
  switch(filterItem.filter_value){
    case 'competitor': return values.brand_filtering === 'second-brands-only'
    case 'competitive-second-brand': return values.brand_filtering === 'master-brands-only'
    case 'start-month': return (
      values.granularity === 'financial-quarter'
      || values.granularity === 'month'
    )
    default: return false
  }
}

export const MediaInvestmentFilterPanel = ({
  dashboardType,
  allowedFilters,
}: MediaInvestmentFilterPanelProps): JSX.Element | null => {

  const location = useLocation()

  const pathFragments = location.pathname.split('/')

  const currencyTypeApplicable = !pathFragments.includes(Reporting.CompetitiveInvestmentSOV)

  const useFiltersHook = filterHooksByDashboardType[dashboardType]

  const { result, loading } = useFiltersHook(allowedFilters)

  const [state, setState] = useUrlState<FilterPanelState>(
    filterItemsToFilterPanelState(allowedFilters)
  )

  const [, setSavedQueryStrings] = useSavedQueryStrings()

  const classes = useStyles()

  const defaultDisplayConfig: FilterPanelProps<FilterPanelState>['displayConfig'] = (result || []).reduce( (acc, filterItem) => {
    filterItem.groups.forEach( group => {
      if( group.group_value === 'start-month' || group.group_value === 'end-month' ){
        return acc
      }
      acc[group.group_value as keyof FilterPanelState] = {
        label: group.group_name,
        render: (value) => {
          const option = group.options.find( o => o.value === value )
          if( option ) return option.name
          return value
        }
      }
    })
    return acc
  }, {} as FilterPanelProps<FilterPanelState>['displayConfig'])

  const displayConfig: FilterPanelProps<FilterPanelState>['displayConfig'] = {
    months: {
      label: 'Months',
      nullable: false,
      render: (value) => value.split('-').map(getMonthDisplayLabel).join(' - '),
    },
    ...defaultDisplayConfig,
    granularity: {
      label: 'Granularity',
      nullable: false,
      render: (value: CompetitiveGranularity | null | undefined) => {
        if( !value || value === 'financial-year' ){
          return 'Financial Year'
        }
        if( value === 'financial-quarter' ){
          return 'Financial Quarter'
        }
        if( value === 'month' ){
          return 'Month'
        }
        return null
      } 
    },
    brand_filtering: {
      label: 'Brand Filtering',
      nullable: false,
      render: (value: CompetitiveBrandFiltering | null | undefined) => {
        if( value === 'master-brands-only' ){
          return 'Master Brands Only'
        }
        if( value === 'aggregate-second-brands' ){
          return 'Aggregate Second Brands'
        }
        if( value === 'second-brands-only' ){
          return 'Second Brands Only'
        }
        return null
      } 
    },
  } as FilterPanelProps<FilterPanelState>['displayConfig']

  // NOTE - we want to hide brand filtering but keep it in the URL to pass between related reports
  let displayFilterOrder = uniq([
    'granularity',
    'months',
    'brand_filtering',
    ...Object.keys(displayConfig).sort()
  ])

  const allowedFilterValues = allowedFilters.map( f => f.filter_value )

  if( !allowedFilterValues.includes('brand-filtering') ){
    displayFilterOrder = displayFilterOrder.filter( f => f !== 'brand_filtering' )
  }

  return (
    <Box display='flex' flexWrap='nowrap' flexBasis='100%'>
      <Card className={classes.card}>
        <FilterPanel
          values={state}
          onConfirm={(values): void => {
            setState(values)
            const searchString = stringifyUrlSearchParams(values)
            const queryStringKey = (
              pathFragments.includes(Reporting.MediaInvestment) ?
                Reporting.MediaInvestment :
                pathFragments.includes(Reporting.CompetitiveInvestmentSOS) ?
                  Reporting.CompetitiveInvestmentSOS :
                  pathFragments.includes(Reporting.CompetitiveInvestmentSOV) ?
                    Reporting.CompetitiveInvestmentSOV :
                    null
            )
            if( queryStringKey ){
              setSavedQueryStrings({ [queryStringKey]: searchString })
            }
          }}
          emptyMessage={
            loading ?
              (
                <Box fontSize={14} display='flex' alignItems='center'>
                  <Loader size={16} preset='inline' mr={2} />
                Loading available filters...
                </Box>
              ) : null
          }
          displayConfig={displayConfig}
          valueOrder={displayFilterOrder}>
          {({ values, updateValues }): JSX.Element => (
            <Grid container spacing={5}>
              { result.map( filterItem => {
                const OverrideFilterItemComponent = filterItemComponentOverrides[filterItem.filter_value]
                if( OverrideFilterItemComponent === null ){
                  return null
                }
                const FilterItemComponent = OverrideFilterItemComponent || DefaultFilterItemComponent
                return (
                  <Grid key={filterItem.filter_value} item xs='auto'>
                    <FilterItemComponent
                      classes={classes}
                      filterItem={filterItem}
                      disabled={isFilterItemDisabled(filterItem, values)}
                      values={values}
                      updateValues={updateValues} />
                  </Grid>
                )
              })}
            </Grid>
          )}
        </FilterPanel>
      </Card>
      { currencyTypeApplicable && (
        <Box ml={2} mt={2}>
          <RoundedPlainTextButtonMenu
            value={state.currency}
            label={
              state.currency === 'dynamic' ?
                'Variable' :
                'Fixed'
            }
            TriggerProps={{
              variant: 'contained',
              color: 'secondary',
              size: 'small',
              startIcon: <EuroSymbol />,
              endIcon: <ArrowDropDown />,
            }}
            options={
            [
              {
                value: 'dynamic',
                label: (
                  <div>
                    <Typography variant='h6'>Variable Exchange Rate</Typography>
                    <Typography className={classes.optionExplainer}>
                      Currency conversion averaged from daily exchange rates
                    </Typography>
                  </div>
                )
              },
              {
                value: 'fixed',
                label: (
                  <div>
                    <Typography variant='h6'>Fixed Exchange Rate</Typography>
                    <Typography className={classes.optionExplainer}>
                      Currency conversion using Vodafone fixed annual exchange rates
                    </Typography>
                  </div>
                )
              },
            ] as MenuOption<CurrencyType>[]
            }
            onChange={(e, value): void => {
              setState({ currency: value })
            }} />
        </Box>
      )}
    </Box>
  )
}
