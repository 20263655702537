import React from 'react'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormGroup,
  FormGroupProps,
  FormLabel,
} from '@material-ui/core'
import { MenuOption } from '../Menus'
import { every, isEqual, some } from 'lodash-es'


export type CheckboxGroupProps<T> = {
  name: React.ReactNode
  value: T[] | undefined
  onChange: (value: T[]) => void
  options: MenuOption<T>[]
  areValuesEqual?: (a: T, b: T) => boolean
  FormGroupProps?: FormGroupProps
  FormControlLabelProps?: Partial<FormControlLabelProps>
  scrollable?: boolean
  maxHeight?: string | number
} & Partial<Pick<FormControlProps, 'color' | 'disabled' | 'size'>>

const stickyLabelCSS: React.CSSProperties = {
  position: 'sticky',
  top: 0,
  backgroundColor: 'inherit',
  zIndex: 1,
}

export function CheckboxGroup<T>({
  name,
  value,
  options,
  onChange,
  areValuesEqual = isEqual,
  FormGroupProps = {},
  FormControlLabelProps = {},
  scrollable,
  maxHeight,
  ...props
}: CheckboxGroupProps<T>): JSX.Element {
  const allValuesEqual = every(options, o => some(value, v => areValuesEqual(o.value, v)))
  const formGroupStyle: React.CSSProperties | undefined = (
    scrollable && maxHeight ?
      { maxHeight, overflowY: 'auto', flexWrap: 'nowrap', ...FormGroupProps.style } :
      FormGroupProps.style
  )
  return (
    <FormControl color='secondary' component='fieldset' {...props}>
      <FormLabel>{name}</FormLabel>
      <FormGroup {...FormGroupProps} style={formGroupStyle}>
        <FormControlLabel
          name='select_all'
          disabled={props.disabled}
          label={
            allValuesEqual ?
              'Deselect All' :
              'Select All'
          }
          control={
            <Checkbox
              indeterminate
              disabled={some(options, o => !!o.disabled)}
              checked={allValuesEqual}
              onChange={(): void => {
                onChange(
                  allValuesEqual ?
                    [] :
                    options.map( o => o.value )
                )
              }} />
          }
          style={scrollable ? stickyLabelCSS : undefined} />
        { options.map( (option, i) => {
          const isSelected = some(value, v => areValuesEqual(v, option.value))
          return (
            <FormControlLabel
              key={i}
              label={option.label}
              disabled={option.disabled}
              control={
                <Checkbox
                  checked={isSelected}
                  onChange={(): void => {
                    if( isSelected ){
                      onChange((value || []).filter( v => !areValuesEqual(v, option.value)))
                    }else{
                      onChange([...(value || []), option.value])
                    }
                  }} />
              }
              {...FormControlLabelProps} />
          )
        })}
      </FormGroup>
    </FormControl>
  )
}
