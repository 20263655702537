import React from 'react'

import { Box, BoxProps, CopyToClipboard, formatMoney, ProviderLogo, SvgIconProps } from '@percept/mui'

import { Check, Close, Warning } from '@percept/mui/icons'

import { ReportProvider } from '@percept/types'
import { coerceReportProvider } from '@percept/utils'


export const StatusIcon = ({
  value,
  iconFontSize,
  ...props
}: {
  value: 'success' | 'error' | 'warning'
  iconFontSize?: SvgIconProps['fontSize']
} & Omit<BoxProps, 'value'>): JSX.Element => {
  const IconComponent = (
    value === 'success' ?
      Check :
      value === 'error' ?
        Close :
        Warning
  )
  return (
    <Box
      mr={1.5}
      color={`${value}.main`}
      display='inline-flex'
      fontSize='1em'
      alignItems='center'
      {...props}>
      <IconComponent color='inherit' fontSize={iconFontSize} />
    </Box>
  )
}


export const booleanRenderer = (value: boolean): React.ReactNode => (
  <StatusIcon
    value={value ? 'success' : 'error'} />
)


export const copyToClipboardRenderer = (value: any): React.ReactNode => (
  <CopyToClipboard
    value={value}
    style={{width: 'min-content'}} />
)


export const providerRenderer = (value: ReportProvider): React.ReactNode => (
  <ProviderLogo
    provider={coerceReportProvider(value)}
    size={1.5}
    units='em'
    style={{position: 'relative', top: 2}} />
)


export const boldRenderer = (value: any): React.ReactNode => (
  <strong>{ value }</strong>
)


type CurrencyRenderer = (value?: number | null) => React.ReactNode

const _currencyRendererCache: Record<string, CurrencyRenderer> = {}

const nullCurrencyRenderer: CurrencyRenderer = (value) => (
  formatMoney({ amount: value || null, abbreviate: false })
)


export const getCurrencyRenderer = (currency?: string): CurrencyRenderer => {
  if( !currency ) return nullCurrencyRenderer
  if( !_currencyRendererCache[currency]) {
    const currencyRenderer: CurrencyRenderer = (value) => (
      formatMoney({ amount: value || null, currency, abbreviate: false })
    )
    _currencyRendererCache[currency] = currencyRenderer
  }
  return _currencyRendererCache[currency]
}
