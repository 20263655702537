import { Box, Tab, Tabs } from '@material-ui/core'
import { useNavigation } from '@percept/hooks'
import { makeAppStyles, RoundedPlainTextButton } from '@percept/mui'
import { useActiveOrgEnvironment } from 'components/Organisation'
import React, { useContext } from 'react'
import { useLocation } from 'react-router'
import { Reporting } from 'ReportingRoutes'
import { DataUpload } from '../DataUpload'
import { UserPrivilegeContext } from '@percept/app-components'
import { userHasOrgPrivileges } from '@percept/utils'
import { VODAFONE_GLOBAL_ID } from 'vodafone/src/vodafoneMarkets'
import { SubmissionTrackerSpendingTypePath } from '../SubmissionTracker'

const useTabClasses = makeAppStyles((theme) => ({
  root: {
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 700,
    minHeight: theme.spacing(6),
    color: 'white',
  },
  buttonsContainer: {
    display: 'flex',
    gap: theme.spacing(3),
  },
  indicator: (
    theme.appComponents.appBarTabIndicator ?
      theme.appComponents.appBarTabIndicator.colorSecondary :
      {
        backgroundColor: theme.palette.secondary.main,
      }
  ),
}))

export const SubnavbarTabs = (): JSX.Element => {
  const tabClasses = useTabClasses()
  const navigate = useNavigation()
  const { pathname } = useLocation()

  const { activePlatformUnit } = useActiveOrgEnvironment()

  const orgUnitId = activePlatformUnit && activePlatformUnit.id

  const editControlsEnabled = !!(orgUnitId && orgUnitId !== VODAFONE_GLOBAL_ID)

  const userPrivileges = useContext(UserPrivilegeContext)

  const canViewCompetitiveReports = userHasOrgPrivileges(
    ['mediaInvestment.competitive.view'],
    orgUnitId,
    userPrivileges.org_privileges,
  )

  const canEditInvestmentData = userHasOrgPrivileges(
    [
      'mediaInvestment.manageData.atl.edit',
      'mediaInvestment.manageData.competitive.edit',
      'mediaInvestment.manageData.digital.edit',
    ],
    orgUnitId,
    userPrivileges.org_privileges,
  )

  const canViewInvestmentData = userHasOrgPrivileges(
    [
      'mediaInvestment.manageData.atl.view',
      'mediaInvestment.manageData.digital.view',
    ],
    orgUnitId,
    userPrivileges.org_privileges,
  )

  const pathFragments = pathname.split('/')

  const activeTab = pathFragments.includes(Reporting.MediaInvestment)
    ? Reporting.MediaInvestment
    : pathFragments.includes(Reporting.CompetitiveInvestment)
      ? Reporting.CompetitiveInvestment
      : pathFragments.includes(Reporting.CompetitiveInvestmentSOS) ?
        Reporting.CompetitiveInvestmentSOS
        : pathFragments.includes(Reporting.CompetitiveInvestmentSOV) ?
          Reporting.CompetitiveInvestmentSOV
          : Reporting.SubmissionTracker

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      pr={2}>
      <Tabs
        key='reporting'
        classes={tabClasses}
        value={activeTab}
        indicatorColor='secondary'
        onChange={(e, value: Reporting): void => {
          navigate(pathname.split(activeTab)[0] + value)
        }}>
        <Tab
          value={Reporting.MediaInvestment}
          classes={tabClasses}
          label='Media Investment'
        />
        {/* { canViewCompetitiveReports && (
          <Tab
            value={Reporting.CompetitiveInvestment}
            classes={tabClasses}
            label='Competitive Investment' />
        )} */}
        { canViewCompetitiveReports && (
          <Tab
            value={Reporting.CompetitiveInvestmentSOS}
            classes={tabClasses}
            label='Competitive Investment SOS' />
        )}
        { canViewCompetitiveReports && (
          <Tab
            value={Reporting.CompetitiveInvestmentSOV}
            classes={tabClasses}
            label='Competitive Investment SOV' />
        )}
      </Tabs>
      <Box className={tabClasses.buttonsContainer}>
        <RoundedPlainTextButton
          variant='contained'
          size='small'
          onClick={(): void => {
            const basePath = pathname.split(activeTab)[0]
            let pathSuffix: SubmissionTrackerSpendingTypePath = 'media-investment'
            if( activeTab === Reporting.CompetitiveInvestment ){
              pathSuffix = pathname.includes('tv-share-of-voice') ? 'competitive-sov' : 'competitive-sos'
            }
            const newPath = `${basePath}${Reporting.SubmissionTracker}/${pathSuffix}`
            navigate(newPath)
          }}>
          Submission Tracker
        </RoundedPlainTextButton>
        { editControlsEnabled && (canViewInvestmentData || canEditInvestmentData) && (
          <RoundedPlainTextButton
            variant='contained'
            size='small'
            onClick={(): void => {
              navigate(pathname.split(activeTab)[0] + Reporting.Form)
            }}>
            Manage Data
          </RoundedPlainTextButton>
        )}
        { editControlsEnabled && canEditInvestmentData && orgUnitId && (
          <DataUpload
            org_unit_id={orgUnitId} />
        )}
      </Box>
    </Box>
  )
}
