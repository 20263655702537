import React, { useMemo } from 'react'

import { formatMoney } from '@percept/mui'

import {
  dateRangeCustomFilterListOptions,
  makeCurrencyRangeCustomFilterListOptions,
  makeCurrencyRangeFilterOptions,
  makeDateFilterOptions,
  makeRangeFilterOptions,
  percentageRangeCustomFilterListOptions,
  rangeCustomFilterListOptions,
} from './filterUtils'

import { EntityAttribute, EntityAttributeProps } from './EntityAttribute'

import { capitalize, get, identity } from 'lodash-es'

import {
  isMonetaryDimension,
  isPercentageDimension,
  isRateBasedDimension,
  separateThousands,
} from '@percept/utils'

import { automaticPrecisionPercentageFormatter, rateFormatter } from '@percept/mui/charts'

import { getDomain } from '@percept/mui/charts/lib'

import { exampleColumnMappings, ExampleColumn, providerColumnMap, DataFrame } from './lib'

import { MUIDataTableColumn, MUIDataTableColumnOptions, MUIDataTableProps } from 'mui-datatables'

import {
  AnyPerformanceDimension,
  MetricMetadataType,
  MetricSegmentType,
  PerformanceReportingDimension,
  ReportProvider,
} from '@percept/types'

import { getColumnOptionsMap } from './columnOptions'

import { performanceReportingDimensionOrder } from '@percept/constants'

import { DataTableClasses } from './styles'


export type UseDataTableColumnsProps = {
  dataFrame: DataFrame
  classes: DataTableClasses
  provider?: ReportProvider
  metadata?: MetricMetadataType
  currency: string | null | undefined
  activeSegments?: MetricSegmentType[]
}


export type UseDataTableColumnsValue = (
  Pick<
    MUIDataTableProps, 'columns'
  > & {
    classes?: DataTableClasses
  }
)


export const useDataTableColumns = ({
  dataFrame,
  classes,
  provider,
  metadata,
  currency,
  activeSegments,
}: UseDataTableColumnsProps): MUIDataTableColumn[] => {

  const value: MUIDataTableColumn[] = useMemo(() => {

    const { rows: data, fieldnames } = dataFrame

    const columnOptionsMap = getColumnOptionsMap({
      classes,
      activeSegments,
      ...dataFrame,
    })

    const withPinnedColumn = (
      renderer: (d: number, i: number) => React.ReactNode
    ): MUIDataTableColumnOptions['customBodyRenderLite'] => {
      const wrappedRenderer: MUIDataTableColumnOptions['customBodyRenderLite'] = (...args) => {
        return (
          <span className={classes.fixedColumnCellContent}>
            { renderer(...args) }
          </span>
        )
      }

      return wrappedRenderer
    }

    return (fieldnames as ExampleColumn[]).map( (fieldname, columnIndex) => {

      let options: MUIDataTableColumnOptions | undefined

      if( metadata && metadata[fieldname] ){

        const metadataEntry = metadata[fieldname]

        const { display_options } = metadataEntry

        const format = display_options.chart_type as EntityAttributeProps['format']

        const isNumeric = (format === 'number' || format === 'currency' || format === 'percentage')

        const isCurrency = format === 'currency'

        const isDate = format === 'date'

        let domain: [number, number] = [0, 0]

        if( isNumeric || isCurrency ){
          domain = getDomain<number>(
            data.map( row => Number(row[columnIndex]) ),
            identity,
            [0, 0]
          )
        }else if( isDate ){
          const dates = data.map( row =>
            row[columnIndex] ?
              new Date(String(row[columnIndex])).getTime() :
              null
          ).filter( (d): d is number => d !== null )

          domain = [
            Math.min(...dates),
            Math.max(...dates),
          ]
        }

        options = {
          ...columnOptionsMap[fieldname] || {},
          customFilterListOptions: (
            isNumeric ?
              rangeCustomFilterListOptions :
              isDate ?
                dateRangeCustomFilterListOptions :
                undefined
          ),
          filterType: ( isNumeric || isDate ) ? 'custom' : 'dropdown',
          filterOptions: (
            isCurrency ? (
              makeCurrencyRangeFilterOptions(domain, currency)
            ) : isNumeric ? (
              makeRangeFilterOptions(domain)
            ) : isDate ? (
              makeDateFilterOptions(domain)
            ) : undefined
          ),
          // eslint-disable-next-line react/display-name
          customBodyRenderLite: (dataIndex): JSX.Element => (
            <EntityAttribute
              value={data[dataIndex][columnIndex]}
              format={format}
              prettyPrint={!!display_options.pretty_print}
              currency={currency} />
          ),
          ...(
            (format === 'list' && {
              filterOptions: {
                // t

              },
              sortCompare: (order) => {
                return (a, b): number => {
                  if( !a.data.length ) return order === 'asc' ? 1 : -1
                  if( !b.data.length ) return order === 'asc' ? -1 : 1
                  if( a.data[0] < b.data[0] ) return order === 'asc' ? -1 : 1
                  if( a.data[0] > b.data[0] ) return order === 'asc' ? 1 : -1
                  return 0
                }
              }
            })
          ),
        }

      }else if( isMonetaryDimension(fieldname as AnyPerformanceDimension) ){

        options = {
          ...columnOptionsMap[fieldname] || {},
          filterType: 'custom',
          customFilterListOptions: makeCurrencyRangeCustomFilterListOptions(currency),
          filterOptions: makeCurrencyRangeFilterOptions(
            getDomain<number>(
              data.map( row => Number(row[columnIndex]) ),
              identity,
              [0, 0]
            ),
            currency
          ),
          customBodyRenderLite: (dataIndex) => {
            const value = data[dataIndex][columnIndex]
            if( value !== null && value !== undefined ){
              return formatMoney({
                amount: Number(value),
                currency,
                abbreviate: false
              })
            }
            return '–'
          },
        } as MUIDataTableColumnOptions

      }else{

        const isPerfDimension = (
          performanceReportingDimensionOrder.includes(fieldname as PerformanceReportingDimension)
          || ['conversion_rate', 'views', 'reach', 'frequency', 'roas_rate'].includes(fieldname)
        )
        const isPercentage = isPercentageDimension(fieldname as PerformanceReportingDimension)
        const isRateBased = isRateBasedDimension(fieldname as PerformanceReportingDimension)
        const formatter = (
          isRateBased || fieldname === 'ctr' ?
            rateFormatter :
            isPercentage ?
              automaticPrecisionPercentageFormatter :
              separateThousands
        ) as ((n: number | string | null) => string)

        options = {
          excluded: Boolean(
            provider && get(
              providerColumnMap, provider, [] as ExampleColumn[]
            ).indexOf(fieldname) !== -1
          ),
          ...columnOptionsMap[fieldname] || {},
          ...(isPerfDimension && {
            filterType: 'custom',
            customFilterListOptions: (
              isPercentage ?
                percentageRangeCustomFilterListOptions :
                rangeCustomFilterListOptions
            ),
            filterOptions: makeRangeFilterOptions(
              getDomain<number>(
                data.map( row => Number(row[columnIndex]) ),
                identity,
                [0, 0]
              ),
              formatter,
            ),
            customBodyRenderLite: (dataIndex): string => {
              const value = data[dataIndex][columnIndex]
              if( value === null || value === undefined ){
                return '–'
              }
              return formatter(value as number | string)
            },
          }),
          
        } as MUIDataTableColumnOptions

      }

      if( columnIndex === 0 && options.customBodyRenderLite ){
        options.customBodyRenderLite = withPinnedColumn(options.customBodyRenderLite)
      }

      return {
        name: (
          metadata && metadata[fieldname] && metadata[fieldname].title
          || exampleColumnMappings[fieldname]
          || capitalize(fieldname)
        ),
        options,
      }
    })

  }, [dataFrame, provider, metadata, currency, activeSegments, classes])

  return value
}
