
import {
  uniq,
  findNext,
  intersection,
  sum,
} from '@percept/utils'

import { get, isNumber, mapKeys } from 'lodash-es'

import {
  MetricSegmentType,
  MetricMetadataDisplayOptions,
  MetricMetadataSegmentType,
  MetricExamplesType,
  HealthType,
  Metric,
  DistributionSegments,
} from '@percept/types'


const { WORKLOAD_TYPE } = process.env

const DEBUG = WORKLOAD_TYPE !== 'PROD'


export const chartTypes = ['histogram', 'donut', 'series']

export const isDistribution = (chartType: string): boolean => chartType === 'series' || chartTypes.includes(chartType)

export const attributeTypes = ['text', 'currency', 'list', 'date']

export const isAttribute = (chartType: string): boolean => attributeTypes.includes(chartType)


export const metricContainsSignal = (metric: Metric, dimension = 'count'): boolean => {
  if( metric.metric_type === 'attribute' ){
    return !!metric.value
  }
  if( metric.metric_type === 'distribution' || metric.metric_type === 'distribution_non_mutex' ){
    return !!(
      sum(
        Object.values(
          get(metric.dimensions, [dimension, 'segments'], {})
        ).map( s => s.value || 0 )
      ) > 0
    )
  }
  if( metric.metric_type === 'proportion' || metric.metric_type === 'ratio' ){
    return !!(
      (get(metric.dimensions, [dimension, 'denominator', 'value']) || 0) +
      (get(metric.dimensions, [dimension, 'enumerator', 'value']) || 0) > 0
    )
  }
  return !!get(metric.dimensions, [dimension, 'value'])
}


/* Metadata mapping */


// const logKeys = (keys: string): string[] => [
//   `%c${keys}`,
//   'background: #5c748c; color: #ebeff3; padding: 2px 5px; font-weight: bold; border-radius: 2px',
// ]

const makeMatchingKey = (key: string): string => key.toUpperCase().trim()

export const applySegmentMetadata = (
  segments: DistributionSegments,
  display_options: MetricMetadataDisplayOptions,
): MetricSegmentType[] => {

  if( !display_options.segments ){
    if( DEBUG ){
      console.warn('Attempted to map distribution segments to metadata without segments')
      console.info('Segments', segments)
      console.info('Display options', display_options)
    }
    return []
  }

  if( !display_options.segments.length && !display_options.dynamic && !display_options.mixed_dynamic ){
    if( DEBUG ){
      console.warn(
        'Attempted to map dynamic distribution segments without correct dynamic configuration - ' +
        'segments are empty and no dynamic / mixed_dynamic flags were found'
      )
      console.info('Segments', segments)
      console.info('Display options', display_options)
    }
    return []
  }

  const targetSegments: MetricMetadataSegmentType[] = display_options.segments

  const isDynamic = display_options.dynamic === true

  const mixedDynamic = display_options.mixed_dynamic === true

  let mappableSegments = targetSegments

  const matchingSegments = mapKeys(segments, (v, k) => k.toUpperCase())

  if( isDynamic ){
    const keys = Object.keys(matchingSegments)
    const prefix = display_options.segment_prefix
    const matchingSegmentMap = Object.keys(segments).reduce( (acc, k) => {
      acc[k.toUpperCase()] = k
      return acc
    }, {} as Record<string, string>)

    mappableSegments = keys.map( (key, i) => ({
      keys: [key],
      // NOTE - the absence of the segment prefix is used to determine
      // whether we use the literal segment keys, or derive an ordinal list
      // of arbitrary segments (e.g 'Profile 1', 'Profile 2', ...)
      label: (
        !prefix ?
          matchingSegmentMap[key] :
          `${prefix} ${i + 1}`
      ),
      health: null,
    }) )

    if( mixedDynamic ){
      mappableSegments = targetSegments.concat(
        mappableSegments.filter( m => {
          return !findNext(targetSegments, s => (
            intersection(
              [...s.keys, s.label].map(makeMatchingKey),
              m.keys.map(makeMatchingKey)
            ).length > 0
          ))
        })
      )
    }
  }

  const mappedData: MetricSegmentType[] = mappableSegments.map( ({ keys, label, ...segment }) => {

    let value = 0,
        examples: MetricExamplesType | null = null

    const health: HealthType = isNumber(segment.health) ? segment.health : null

    const matchingKeys = uniq(keys.concat([label]).map(makeMatchingKey))

    const matchedKey = findNext(matchingKeys, k => typeof matchingSegments[k] !== 'undefined' )

    if( matchedKey ){
      value = matchingSegments[matchedKey].value || 0
      examples = matchingSegments[matchedKey].examples || null
    }else{
      // if( DEBUG ){
      //   console.groupCollapsed(`Missing segment key`)
      //   console.log(...logKeys(matchingKeys.join(' | ')))
      //   console.log(`Label: ${label}`)
      //   console.log('Data:', segments)
      //   console.groupEnd()
      // }
    }

    return { label, value, health, examples }
  })

  return mappedData

}


export const sizeMappings = {
  micro: {
    titleTag: 'h6',
    containerHeight: '2rem',
    donut: { outerRadius: 50, innerRadius: 30, size: '2rem' }
  },
  mini: {
    titleTag: 'h6',
    containerHeight: '4rem',
    donut: { outerRadius: 50, innerRadius: 30, size: '4rem' }
  },
  midi: {
    titleTag: 'h6',
    containerHeight: '7rem',
    donut: { outerRadius: 50, innerRadius: 30, size: '7rem' }
  },
  small: {
    titleTag: 'h5',
    containerHeight: 100,
    donut: { outerRadius: 50, innerRadius: 30, size: '7rem' }
  },
  medium: {
    titleTag: 'h4',
    containerHeight: '10rem',
    donut: { outerRadius: 50, innerRadius: 26, size: '12rem' }
  },
  large: {
    titleTag: 'h3',
    containerHeight: '12rem',
    donut: { outerRadius: 40, innerRadius: 25 }
  }
}

export const displayMappings = {
  SUMMARY: {
    showDescriptions: true,
    showAxes: true,
    showLabels: false,
    showTicks: false,
    ticks: 1,
    ...sizeMappings.small
  },
  DETAIL: {
    showDescriptions: true,
    showAxes: true,
    showLabels: true,
    showTicks: true,
    ticks: 5,
    donutFontSize: '1.1rem',
    ...sizeMappings.medium
  },
  MIDI: {
    showDescriptions: false,
    showAxes: true,
    showLabels: false,
    showTicks: false,
    ticks: 1,
    ...sizeMappings.midi,
  },
  MINI: {
    showDescriptions: false,
    showAxes: true,
    showLabels: false,
    showTicks: false,
    ticks: 1,
    ...sizeMappings.mini
  },
  MICRO: {
    showDescriptions: false,
    showAxes: true,
    showLabels: false,
    showTicks: false,
    ticks: 1,
    ...sizeMappings.micro
  }
}
